import { useAdmin } from "ui/hooks/useAdmin"
import { useCallback, useMemo } from "react"
import { Link } from "ui/widgets"

const MainMenu = ({context, _path, fieldPath, fieldEntity, onActivate, dispatch, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "MainMenu")
    const val0 = {"en":"Company","it":"Societa'","de":"Unternehmen"}
    const val1 = {"en":"/en/company","it":"/it/societa","de":"/de/unternehmen"}
    const h0 = useCallback(() => {
        onActivate(null)
    }, [onActivate])
    const val2 = {"en":"Activities","it":"Attività","de":"Aktivitäten"}
    const val3 = {"en":"/en/activities","it":"/it/attivita","de":"/de/aktivitaten"}
    const val4 = {"en":"Products","it":"Prodotti","de":"Produkte"}
    const val5 = {"en":"/en/products","it":"/it/prodotti","de":"/de/produkte"}
    const val6 = {"en":"Technology","it":"Tecnologia","de":"Technologie"}
    const val7 = {"en":"/en/technology","it":"/it/tecnologia","de":"/de/technologie"}
    const val8 = {"en":"Contact","it":"Contatto","de":"Kontakt"}
    const val9 = {"en":"/en/contact","it":"/it/contatto","de":"/de/kontakt"}

    return (
        <nav {...props} ref={ref}  elem-main-menu="" main-menu="">
            <ul>
                <li>
                    <Link context={context} _path={newPath} to={val1[context.language]??val1.en} onActivate={h0}>{val0[context.language]??val0.en}</Link>
                </li>
                <li>
                    <Link context={context} _path={newPath} to={val3[context.language]??val3.en} onActivate={h0}>{val2[context.language]??val2.en}</Link>
                </li>
                <li>
                    <Link context={context} _path={newPath} to={val5[context.language]??val5.en} onActivate={h0}>{val4[context.language]??val4.en}</Link>
                </li>
                <li>
                    <Link context={context} _path={newPath} to={val7[context.language]??val7.en} onActivate={h0}>{val6[context.language]??val6.en}</Link>
                </li>
                <li>
                    <Link context={context} _path={newPath} to={val9[context.language]??val9.en} onActivate={h0}>{val8[context.language]??val8.en}</Link>
                </li>
            </ul>
        </nav>
    )
}
export default MainMenu
/*
{
  "layout": {
    "data": {
      "name": "nav",
      "attr": {
        "main-menu": ""
      }
    },
    "children": [
      {
        "data": {
          "name": "ul"
        },
        "children": [
          {
            "data": {
              "name": "li"
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/societa",
                        "content": "Societa'"
                      },
                      "de": {
                        "to": "/de/unternehmen",
                        "content": "Unternehmen"
                      }
                    },
                    "to": "/en/company",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "content": {
                        "is": "exprValExt"
                      },
                      "onActivate": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "action",
                        "is": "exprValExt"
                      }
                    },
                    "content": "Company",
                    "onActivate": [
                      "call",
                      [
                        "get",
                        "attrs",
                        "onActivate"
                      ],
                      null
                    ]
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "li"
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/attivita",
                        "content": "Attività"
                      },
                      "de": {
                        "to": "/de/aktivitaten",
                        "content": "Aktivitäten"
                      }
                    },
                    "to": "/en/activities",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "content": {
                        "is": "exprValExt"
                      },
                      "onActivate": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "action",
                        "is": "exprValExt"
                      }
                    },
                    "content": "Activities",
                    "onActivate": [
                      "call",
                      [
                        "get",
                        "attrs",
                        "onActivate"
                      ],
                      null
                    ]
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "li"
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/prodotti",
                        "content": "Prodotti"
                      },
                      "de": {
                        "to": "/de/produkte",
                        "content": "Produkte"
                      }
                    },
                    "to": "/en/products",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "content": {
                        "is": "exprValExt"
                      },
                      "onActivate": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "action"
                      }
                    },
                    "content": "Products",
                    "onActivate": [
                      "call",
                      [
                        "get",
                        "attrs",
                        "onActivate"
                      ],
                      null
                    ]
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "li"
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/tecnologia",
                        "content": "Tecnologia"
                      },
                      "de": {
                        "to": "/de/technologie",
                        "content": "Technologie"
                      }
                    },
                    "to": "/en/technology",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "content": {
                        "is": "exprValExt"
                      },
                      "onActivate": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "action"
                      }
                    },
                    "content": "Technology",
                    "onActivate": [
                      "call",
                      [
                        "get",
                        "attrs",
                        "onActivate"
                      ],
                      null
                    ]
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "li"
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/contatto",
                        "content": "Contatto"
                      },
                      "de": {
                        "to": "/de/kontakt",
                        "content": "Kontakt"
                      }
                    },
                    "to": "/en/contact",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "content": {
                        "is": "exprValExt"
                      },
                      "onActivate": {
                        "1": {
                          "_type": "op"
                        },
                        "_type": "action"
                      }
                    },
                    "content": "Contact",
                    "onActivate": [
                      "call",
                      [
                        "get",
                        "attrs",
                        "onActivate"
                      ],
                      null
                    ]
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
