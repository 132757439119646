import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import Select from "ui/Select"
import ImageList from "ui/ImageList"
import WithLightbox from "ui/WithLightbox"
import String from "ui/String"

const CategoryProducts = ({context, _path, fieldPath, fieldEntity, cat, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "CategoryProducts")
    const al0 = () => [ {a: "cat.ref", v: cat} ]

    return (
        <div {...props} ref={ref}  elem-category-products="">
            <Select context={context} _path={newPath} collection="product" type="product" cond={al0}>{(context, selectResults, selectTotal) => (<>
                {context.selectResults?.map((item, itemIndex) => (
                    <div className="product" key={itemIndex} _index={itemIndex}>
                        <WithLightbox lightbox={<ImageList context={context} _path={newPath} fieldPath="img" img={item?.img} elem-img="" type-img="" fieldEntity={item}/>}>{lightboxProps => (<ImageList context={context} _path={newPath} fieldPath="img" img={item?.img} elem-img="" type-img="" fieldEntity={item} {...lightboxProps}/>)}</WithLightbox>
                        <String context={context} _path={newPath} fieldPath="title" string={(item?._i18n?.[context?.language]?.title??item?.title)} elem-string="" type-string="" fieldEntity={item}/>
                    </div>
                ))}
            </>)}</Select>
        </div>
    )
}
export default CategoryProducts
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Select",
          "attr": {
            "entityType": "product",
            "cond": [
              {
                "a": "cat.ref",
                "_e": {
                  "v": {
                    "_type": "op"
                  }
                },
                "v": [
                  "get",
                  "attrs",
                  "cat"
                ]
              }
            ],
            "_e": {
              "cond": {
                "_type": "attrList"
              }
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "Map",
              "attr": {
                "target": [
                  "f",
                  "selectResults"
                ],
                "_e": {
                  "target": {
                    "_type": "op"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "product"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "op"
                        }
                      },
                      "name": [
                        "get",
                        "item",
                        "img"
                      ]
                    },
                    "children": [
                      {
                        "data": {
                          "name": "Lightbox"
                        },
                        "children": [
                          {
                            "data": {
                              "_e": {
                                "name": {
                                  "_type": "op"
                                }
                              },
                              "name": [
                                "get",
                                "item",
                                "img"
                              ]
                            }
                          }
                        ]
                      }
                    ]
                  },
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "op"
                        }
                      },
                      "name": [
                        "get",
                        "item",
                        "title"
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
