import Site from "./Site"
import NodeWrap from "./NodeWrap"
import Homepage from "./Homepage"
import Header from "./Header"
import Footer from "./Footer"
import MainMenu from "./MainMenu"
import Page from "./Page"
import Block from "./Block"
import BIcon from "./BIcon"
import Slideshow from "./Slideshow"
import CategoryList from "./CategoryList"
import CategoryProducts from "./CategoryProducts"
import ProductCarousel from "./ProductCarousel"
import Contact from "./Contact"
import InputText from "./InputText"
import InputTextarea from "./InputTextarea"
import LanguageManager from "./LanguageManager"
import Slogan from "./Slogan"
import {registerElements} from "ui/register"
const elements = { Site, NodeWrap, Homepage, Header, Footer, MainMenu, Page, Block, BIcon, Slideshow, CategoryList, CategoryProducts, ProductCarousel, Contact, InputText, InputTextarea, LanguageManager, Slogan }
const elementTypes = { homepage: Homepage, page: Page, block: Block, bIcon: BIcon, slideshow: Slideshow, categoryList: CategoryList, productCarousel: ProductCarousel, contact: Contact }
registerElements(elements, elementTypes)
export { Site, NodeWrap, Homepage, Header, Footer, MainMenu, Page, Block, BIcon, Slideshow, CategoryList, CategoryProducts, ProductCarousel, Contact, InputText, InputTextarea, LanguageManager, Slogan }
