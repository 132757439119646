import { $lineage, $union, $keyOrder } from "./symbols"

export const impl = (t, typeClass) => t?.classes?.includes(typeClass)
export const is = (t, typeName) => t?.[$lineage]?.includes(typeName)
export const hasField = (t, field) => (t?.[$keyOrder] ?? []).includes(field)
export const keyOrder = t => t?.[$keyOrder]
export const unionType = (t, level = 0) => {
    let _type = t?._type?.is ? t?._type.is : t?._type // correct obj {is}
    _type = (typeof _type === "string" ? [_type] : _type)?.[level]
    if (!_type) {
        _type = t?.[$union]?.[level]?.[0]
    }
    return typeof _type === "string" ? _type : _type?.is
}
export const realType = t => unionType(t) ?? t?.is
