import C from "../config"
import { initClasses } from "./classes"
import {
    buildPath,
    getType,
    getKeyType,
    typeFromTypeDef,
    getSettings,
    getEntityTypes,
} from "./type"
import { is, impl, hasField, keyOrder, unionType, realType } from "./type-utils"

import Data from "./data"
import { convert } from "./converters"
import { validate } from "./validators"
import { load, loadById } from "./load"

import { fold } from "./fold"
initClasses()

export const Type = {
    buildPath,
    getType,
    getKeyType,
    typeFromTypeDef,
    getEntityTypes,
    getSettings,
    is,
    impl,
    hasField,
    keyOrder,
    unionType,
    realType,
}
export { Data }
const getTypeField = (info, field, options) => {
    if (options?.language) {
        let ret = info?._i18n?.[options.language]?.[field]
        if (ret) return ret
    }
    return info[field]
}
const getId = e => e?._id
const getFieldTranslations = (entity, fieldName) => {
    if (!C.LANGUAGES || C.LANGUAGES.length < 2) return null
    const typeInfo = Type.getKeyType(fieldName, entity)
    if (typeInfo?._t === false) return null
    const defaultLanguage = entity?._lang ?? C.LANGUAGES[0]
    return C.LANGUAGES.reduce(
        (acc, language) =>
            Data.get(entity, fieldName, { defaultLanguage, language }) ? [...acc, language] : acc,
        []
    )
}
export const Entity = {
    Type,
    Data,
    typeFromTypeDef,
    getType,
    getKeyType,
    getEntityTypes,

    impl: Data.impl,
    typeImpl: impl,
    is: Data.is,
    typeIs: is,
    getCollection: Data.getCollection,
    getSettings,
    hasField,
    keyOrder,
    unionType,
    realType,

    set: Data.set,
    unset: Data.unset,
    get: Data.get,
    format: Data.format,
    formatField: Data.formatField,
    getTypeField,
    getId,
    getPath: Data.getPath,
    getFieldTranslations,
    validate,
    convert,

    create: Data.create,
    load,
    loadById,

    getFieldOrder: Data.getFieldOrder,
    fold,

    createValue: Data.createValue,
    getValue: Data.getValue,
    setValue: Data.setValue,
    appendValue: Data.appendValue,
}
