import { useAdmin } from "ui/hooks/useAdmin"
import { useLocalState } from "ui/hooks/useLocalState"
import { useMemo } from "react"
import Slider from "ui/Slider"
import ItemTransition from "ui/ItemTransition"
import ImageList from "ui/ImageList"

const Slideshow = ({context, _path, fieldPath, fieldEntity, slideshow, config, ...props}) => {


    const [lstate, ldispatch] = useLocalState({active: 0}, {}, [])
    const newContext = useMemo(() => ({
        ...context,
        slideshow,
    }), [context, slideshow])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, slideshow, "Slideshow")

    return (
        <div {...props} ref={ref}  elem-slideshow="" type-slideshow="">
            <Slider context={newContext} _path={newPath} items={slideshow} active={lstate?.active}>{(newContext, activeSlide, active) => (<>
                <div className="images">
                    <ItemTransition context={newContext} _path={newPath} itemKey={newContext.activeSlide?.img?.[0]?.url}>
                        <ImageList context={newContext} _path={newPath} bg="1" fieldPath="img" img={newContext.activeSlide?.img} elem-img="" type-img="" fieldEntity={activeSlide}/>
                    </ItemTransition>
                </div>
                <div className="text">
                    {slideshow?.map((item, itemIndex) => (
                        <div active={newContext.active===itemIndex?"1":"0"} data-index={itemIndex} key={itemIndex} _index={itemIndex}>
                            <h2 field-text="" type-text="">{(item?._i18n?.[context?.language]?.text??item?.text)}</h2>
                            <h3 field-subtitle="" type-text="">{(item?._i18n?.[context?.language]?.subtitle??item?.subtitle)}</h3>
                        </div>
                    ))}
                </div>
            </>)}</Slider>
        </div>
    )
}
export default Slideshow
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Slider",
          "attr": {
            "items": [
              "f",
              "e"
            ],
            "_e": {
              "items": {
                "_type": "op",
                "is": "exprValExt"
              },
              "active": {
                "_type": "op"
              }
            },
            "active": [
              "f",
              "state.active"
            ]
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "images"
              }
            },
            "children": [
              {
                "data": {
                  "name": "ItemTransition",
                  "attr": {
                    "itemKey": [
                      "get",
                      "activeSlide",
                      "img.0.url"
                    ],
                    "_e": {
                      "itemKey": {
                        "_type": "op",
                        "is": "exprValExt"
                      }
                    }
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "op"
                        }
                      },
                      "name": [
                        "get",
                        "activeSlide",
                        "img"
                      ],
                      "attr": {
                        "bg": "1"
                      }
                    }
                  }
                ]
              }
            ]
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "text"
              }
            },
            "children": [
              {
                "data": {
                  "name": "Map",
                  "attr": {
                    "target": [
                      "f",
                      "e"
                    ],
                    "_e": {
                      "target": {
                        "_type": "op"
                      }
                    }
                  }
                },
                "children": [
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "active": [
                          "if",
                          [
                            "=",
                            [
                              "f",
                              "active"
                            ],
                            [
                              "f",
                              "itemIndex"
                            ]
                          ],
                          "1",
                          "0"
                        ],
                        "_e": {
                          "active": {
                            "1": {
                              "1": {
                                "_type": "op"
                              },
                              "2": {
                                "_type": "op"
                              },
                              "_type": "op"
                            },
                            "_type": "op",
                            "is": "exprValExt"
                          },
                          "data-index": {
                            "_type": "op",
                            "is": "exprValExt"
                          }
                        },
                        "data-index": [
                          "f",
                          "itemIndex"
                        ]
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "name": "h2",
                          "attr": {
                            "content": [
                              "get",
                              "item",
                              "text"
                            ],
                            "_e": {
                              "content": {
                                "_type": "op"
                              }
                            }
                          }
                        }
                      },
                      {
                        "data": {
                          "name": "h3",
                          "attr": {
                            "content": [
                              "get",
                              "item",
                              "subtitle"
                            ],
                            "_e": {
                              "content": {
                                "_type": "op"
                              }
                            }
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "context": {
    "state": {
      "active": 0,
      "_e": {
        "active": {
          "_type": "int",
          "is": "exprVal"
        }
      }
    }
  }
}
*/
