import { useCallback, useState, useMemo, useRef } from "react"

const read = (key, initialValue) => {
    if (!key) return initialValue
    try {
        const v = localStorage.getItem(key)
        return v ? JSON.parse(v) : initialValue
    } catch (error) {
        // Return default value if JSON parsing fails
        console.log(error)
        return initialValue
    }
}
export const useLocalStorage = (key, initialValue) => {
    const value = useRef()
    const [, trigger] = useState(false)

    useMemo(() => {
        value.current = read(key, initialValue)
    }, [key, initialValue])

    const setValue = useCallback(
        v => {
            value.current = v
            if (!key) return
            localStorage.setItem(key, JSON.stringify(v))
            trigger()
        },
        [key]
    )

    return [value.current, setValue]
}
