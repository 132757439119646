import { useAdmin } from "ui/hooks/useAdmin"
import { useCallback, useMemo } from "react"
import { submit } from "./actions/submit"
import { useLocalState } from "ui/hooks/useLocalState"
import InputText from "./InputText"
import InputTextarea from "./InputTextarea"

const Contact = ({context, _path, fieldPath, fieldEntity, contact, config, ...props}) => {


    const [lstate, ldispatch] = useLocalState({stage: 0, contact: [{"a":"name"},{"a":"email"},{"a":"phone"},{"a":"message"}]}, {}, [])
    const newContext = useMemo(() => ({
        ...context,
        contact,
    }), [context, contact])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, contact, "Contact")
    const val0 = {"en":"Contact us","de":"Kontaktiere uns","it":"Contattaci"}
    const val1 = {"en":"name"}
    const val2 = {"en":"Your name","de":"Name","it":"Nome"}
    const h0 = useCallback(e => {
        ldispatch({type:"CHANGE", field: "contact.0.v", value: e.target.value})
    }, [ldispatch])
    const h1 = useCallback(e => {
        ldispatch({type:"CHANGE", field: "contact.1.v", value: e.target.value})
    }, [ldispatch])
    const h2 = useCallback(e => {
        ldispatch({type:"CHANGE", field: "contact.2.v", value: e.target.value})
    }, [ldispatch])
    const val3 = {"en":"phone"}
    const val4 = {"en":"Phone","de":"Telefon","it":"Telefono"}
    const h3 = useCallback(e => {
        ldispatch({type:"CHANGE", field: "contact.3.v", value: e.target.value})
    }, [ldispatch])
    const val5 = {"en":"message"}
    const val6 = {"en":"Message","de":"Nachricht","it":"Messaggio"}
    const val7 = {"en":"Send message","de":"Senden","it":"Spedire"}
    const h4 = useCallback(async () => {
        let stage = lstate.stage
        stage = 1
                ldispatch({type:"CHANGE", field: "stage", value: stage})
        await submit("contact", (lstate?._i18n?.[context?.language]?.contact??lstate?.contact))
        stage = 2

        ldispatch({type:"CHANGE", field: "stage", value: stage})
    }, [ldispatch,lstate])
    const val8 = {"en":"Thank you!","it":"Grazie!","de":"Danke schön!"}
    const val9 = {"en":"We appreciate that you’ve taken the time to write us. We’ll get back to you very soon.","it":"Apprezziamo che tu abbia avuto il tempo di scriverci. Ti risponderemo molto presto.","de":"Wir freuen uns, dass Sie sich die Zeit genommen haben, uns zu schreiben. Wir werden uns sehr bald bei Ihnen melden."}
    const val10 = {"en":"Sending message...","de":"Schicke Nachricht...","it":"Invio messaggio..."}

    return (
        <div {...props} ref={ref}  elem-contact="">
            <h2>{val0[context.language]??val0.en}</h2>
            {(lstate?._i18n?.[context?.language]?.stage??lstate?.stage)===0 && (<div className="contact-wrap">
                <div>
                    <InputText context={newContext} _path={newPath} name={val1[context.language]??val1.en} label={val2[context.language]??val2.en} value={(lstate?.contact?.[0]?._i18n?.[context?.language]?.v??lstate?.contact?.[0]?.v)} onChange={h0}/>
                    <InputText context={newContext} _path={newPath} name="email" label="E-mail" value={(lstate?.contact?.[1]?._i18n?.[context?.language]?.v??lstate?.contact?.[1]?.v)} onChange={h1}/>
                    <InputText context={newContext} _path={newPath} value={(lstate?.contact?.[2]?._i18n?.[context?.language]?.v??lstate?.contact?.[2]?.v)} onChange={h2} name={val3[context.language]??val3.en} label={val4[context.language]??val4.en}/>
                </div>
                <div>
                    <InputTextarea context={newContext} _path={newPath} value={(lstate?.contact?.[3]?._i18n?.[context?.language]?.v??lstate?.contact?.[3]?.v)} onChange={h3} name={val5[context.language]??val5.en} label={val6[context.language]??val6.en}/>
                </div>
            </div>)}
            {(lstate?._i18n?.[context?.language]?.stage??lstate?.stage)===0 && (<button onClick={h4}>{val7[context.language]??val7.en}</button>)}
            {(lstate?._i18n?.[context?.language]?.stage??lstate?.stage)===2 && (<div>
                <h3>{val8[context.language]??val8.en}</h3>
                <p>{val9[context.language]??val9.en}</p>
            </div>)}
            {(lstate?._i18n?.[context?.language]?.stage??lstate?.stage)===1 && (<div>{val10[context.language]??val10.en}</div>)}
        </div>
    )
}
export default Contact
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "h2",
          "attr": {
            "_i18n": {
              "de": {
                "content": "Kontaktiere uns"
              },
              "it": {
                "content": "Contattaci"
              }
            },
            "content": "Contact us",
            "_e": {
              "content": {
                "is": "exprValExt"
              }
            }
          }
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "className": "contact-wrap",
            "_e": {
              "className": {
                "is": "exprValExt"
              },
              "if": {
                "1": {
                  "_type": "op"
                },
                "2": {
                  "_type": "int"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "if": [
              "=",
              [
                "f",
                "state.stage"
              ],
              0
            ]
          }
        },
        "children": [
          {
            "data": {
              "name": "div"
            },
            "children": [
              {
                "data": {
                  "name": "InputText",
                  "attr": {
                    "_i18n": {
                      "de": {
                        "label": "Name"
                      },
                      "it": {
                        "label": "Nome"
                      }
                    },
                    "name": "name",
                    "_e": {
                      "name": {
                        "is": "exprValExt"
                      },
                      "label": {
                        "is": "exprValExt"
                      },
                      "value": {
                        "_type": "op",
                        "is": "exprValExt"
                      },
                      "onChange": {
                        "_type": "action",
                        "is": "exprValExt"
                      }
                    },
                    "label": "Your name",
                    "value": [
                      "f",
                      "state.contact.0.v"
                    ],
                    "onChange": [
                      "setState",
                      "contact.0.v",
                      "_"
                    ]
                  }
                }
              },
              {
                "data": {
                  "name": "InputText",
                  "attr": {
                    "name": "email",
                    "label": "E-mail",
                    "value": [
                      "f",
                      "state.contact.1.v"
                    ],
                    "_e": {
                      "value": {
                        "_type": "op"
                      },
                      "onChange": {
                        "_type": "action"
                      }
                    },
                    "onChange": [
                      "setState",
                      "contact.1.v",
                      "_"
                    ]
                  }
                }
              },
              {
                "data": {
                  "name": "InputText",
                  "attr": {
                    "_i18n": {
                      "de": {
                        "label": "Telefon"
                      },
                      "it": {
                        "label": "Telefono"
                      }
                    },
                    "value": [
                      "f",
                      "state.contact.2.v"
                    ],
                    "_e": {
                      "value": {
                        "_type": "op",
                        "is": "exprValExt"
                      },
                      "onChange": {
                        "_type": "action",
                        "is": "exprValExt"
                      },
                      "name": {
                        "is": "exprValExt"
                      },
                      "label": {
                        "is": "exprValExt"
                      }
                    },
                    "onChange": [
                      "setState",
                      "contact.2.v",
                      "_"
                    ],
                    "name": "phone",
                    "label": "Phone"
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "div"
            },
            "children": [
              {
                "data": {
                  "name": "InputTextarea",
                  "attr": {
                    "_i18n": {
                      "de": {
                        "label": "Nachricht"
                      },
                      "it": {
                        "label": "Messaggio"
                      }
                    },
                    "value": [
                      "f",
                      "state.contact.3.v"
                    ],
                    "_e": {
                      "value": {
                        "_type": "op",
                        "is": "exprValExt"
                      },
                      "onChange": {
                        "_type": "action",
                        "is": "exprValExt"
                      },
                      "name": {
                        "is": "exprValExt"
                      },
                      "label": {
                        "is": "exprValExt"
                      }
                    },
                    "onChange": [
                      "setState",
                      "contact.3.v",
                      "_"
                    ],
                    "name": "message",
                    "label": "Message"
                  }
                }
              }
            ]
          }
        ]
      },
      {
        "data": {
          "name": "button",
          "attr": {
            "_i18n": {
              "de": {
                "content": "Senden"
              },
              "it": {
                "content": "Spedire"
              }
            },
            "onClick": [
              [
                "setState",
                "stage",
                1
              ],
              [
                "submit",
                "contact",
                [
                  "f",
                  "state.contact"
                ]
              ],
              [
                "setState",
                "stage",
                2
              ]
            ],
            "_e": {
              "onClick": {
                "0": {
                  "2": {
                    "_type": "int"
                  }
                },
                "1": {
                  "2": {
                    "_type": "op"
                  }
                },
                "2": {
                  "2": {
                    "_type": "int"
                  }
                },
                "_type": "actionList",
                "is": "exprValExt"
              },
              "content": {
                "is": "exprValExt"
              },
              "if": {
                "1": {
                  "_type": "op"
                },
                "2": {
                  "_type": "int"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "content": "Send message",
            "if": [
              "=",
              [
                "f",
                "state.stage"
              ],
              0
            ]
          }
        }
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "if": [
              "=",
              [
                "f",
                "state.stage"
              ],
              2
            ],
            "_e": {
              "if": {
                "1": {
                  "_type": "op"
                },
                "2": {
                  "_type": "int"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "h3",
              "attr": {
                "_i18n": {
                  "it": {
                    "content": "Grazie!"
                  },
                  "de": {
                    "content": "Danke schön!"
                  }
                },
                "content": "Thank you!"
              }
            }
          },
          {
            "data": {
              "name": "p",
              "attr": {
                "_i18n": {
                  "it": {
                    "content": "Apprezziamo che tu abbia avuto il tempo di scriverci. Ti risponderemo molto presto."
                  },
                  "de": {
                    "content": "Wir freuen uns, dass Sie sich die Zeit genommen haben, uns zu schreiben. Wir werden uns sehr bald bei Ihnen melden."
                  }
                },
                "content": "We appreciate that you’ve taken the time to write us. We’ll get back to you very soon."
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": "div",
          "attr": {
            "_i18n": {
              "de": {
                "content": "Schicke Nachricht..."
              },
              "it": {
                "content": "Invio messaggio..."
              }
            },
            "if": [
              "=",
              [
                "f",
                "state.stage"
              ],
              1
            ],
            "_e": {
              "if": {
                "1": {
                  "_type": "op"
                },
                "2": {
                  "_type": "int"
                },
                "_type": "op",
                "is": "exprValExt"
              },
              "content": {
                "is": "exprValExt"
              }
            },
            "content": "Sending message..."
          }
        }
      }
    ]
  },
  "context": {
    "state": {
      "stage": 0,
      "_e": {
        "stage": {
          "_type": "int"
        },
        "contact": {
          "_type": "attrList"
        }
      },
      "contact": [
        {
          "a": "name"
        },
        {
          "a": "email"
        },
        {
          "a": "phone"
        },
        {
          "a": "message"
        }
      ]
    }
  }
}
*/
