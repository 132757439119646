import { getType, getKeyType, getSettings } from "./type"
import Data from "./data"
import { $lineage } from "./symbols"

/**
   Entity.fold(entity,
              (acc, value, fieldName, fieldInfo, path) => acc,
              initialAcc)
*/

const foldone = (acc, parent, parentPath, parentInfo, cb, field, settingsMap) => {
    //console.log("FOLDONE", parentPath, field, parent, parentInfo)
    const path = [...parentPath, field]
    const fieldInfo = getKeyType(field, parent, parentInfo)
    const value = Data.get(parent, field, { entityInfo: parentInfo })
    //console.log("FOLDONETYPE:", fieldInfo, value)
    //if (value === null || typeof value === "undefined") return acc
    return foldrec(
        cb(acc, value, field, fieldInfo, path.join(".")),
        value,
        path,
        fieldInfo,
        settingsMap ?? parent?._e?.[field],
        cb
    )
}
const foldrec = (acc, parent, parentPath, parentInfo, settingsMap, cb) => {
    //console.log("FOLDREC", acc, parent, parentPath, parentInfo)
    //console.log("FOLDREC", parent, parentInfo)
    let ret =
        (parentInfo?.[$lineage]?.includes("list")
            ? parent?.reduce(
                  (acc, value, i) =>
                      foldone(acc, parent, parentPath, parentInfo, cb, `${i}`, settingsMap?.[i]),
                  acc
              )
            : Object.keys(parentInfo?.keys ?? {})?.reduce(
                  (acc, field) => foldone(acc, parent, parentPath, parentInfo, cb, field),
                  acc
              )) ?? acc
    const settingsList = settingsMap ? Object.keys(settingsMap) : null
    if (!settingsList) return ret
    const settings = getSettings(parentInfo)?.filter(s => settingsList.includes(s)) ?? []
    //console.log("SETTINGS", settingsMap, settings)
    if (settings.length === 0) return ret
    return (
        settings.reduce(
            (acc, field) => foldone(acc, parent, parentPath, parentInfo, cb, field),
            ret
        ) ?? ret
    )
}
export const fold = (entity, cb, initial) =>
    foldrec(initial, entity, [], getType(entity), entity._c, cb)
