import customConverters from "../conf/converters"
//import { convert } from "./data"
import { getKeyType } from "./type"
import { $lineage } from "./symbols"

const converters = {
    t: (value, type) => {
        //console.log("convert t", value, type)
        if (typeof value === "undefined" || value === null) {
            if (type.optional) return value
            if (type.default) return type.default
        }
        return value
    },
    bool: value => {
        if (typeof value === "undefined") return value
        return typeof value === "boolean" ? value : !!value
    },
    string: value => {
        //console.log("convert string", value, type)
        if (typeof value === "undefined" || value === null) return value
        if (typeof value !== "string") return ""
        return value
    },
    number: value => {
        if (typeof value === "undefined" || value === null) return value
        if (typeof value !== "number") return 0
        return value
    },
    list: (value, type) => {
        //console.log("convert list", value, type)
        let val = value
        if (typeof value === "undefined" || value === null) {
            if (!type.min) return value
        }
        if (!Array.isArray(value)) {
            if (type.default) return type.default
            //if (type.optional || (!type.min && !type.default)) return undefined
            val = []
        }
        if (type.min) {
            while (val.length < type.min) {
                val = [...val, null]
            }
        }
        if (type.max && val.length > type.max) val = val.slice(0, type.max)
        return val
    },
    map: value => {
        if (typeof value === "undefined" || value === null) return value
        if (Array.isArray(value) || typeof value !== "object") return null
        return value
    },
    value: v => ({
        ...v,
        value: convert(v.value, getKeyType("value", v)),
    }),
    ...customConverters,
}

export const convert = (value, type) =>
    type?.[$lineage]?.reduceRight((acc, t) => {
        if (converters[t]) return converters[t](acc, type)
        return acc
    }, value) ?? null

//export default converters
