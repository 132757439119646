import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import ImageList from "ui/ImageList"
import WithLightbox from "ui/WithLightbox"

const Slogan = ({context, _path, fieldPath, fieldEntity, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "Slogan")
    const val0 = {"en":"ReNiCo: your reliable partner!","it":"ReNiCo: un partner affidabile!","de":"ReNiCo: ein solider und zuverlässiger Partner!"}

    return (
        <div {...props} ref={ref}  elem-slogan="">
            <div>
                <ImageList context={context} _path={newPath} img={[{"name":"cert-dekra.png","url":"/upload/element/exprVal/2021/11/22/cert-dekra.png","mime":"image/png","w":203,"h":203}]}/>
                <WithLightbox lightbox={<ImageList context={context} _path={newPath} img={[{"name":"cert-DEKRA.jpg","url":"/upload/element/exprVal/2021/12/7/cert-dekra-1.jpg","mime":"image/jpeg","w":1226,"h":1754}]}/>}>{lightboxProps => (<ImageList context={context} _path={newPath} className="cert-lightbox" img={[{"name":"cert-DEKRA.jpg","url":"/upload/element/exprVal/2021/12/7/cert-dekra.jpg","mime":"image/jpeg","w":1226,"h":1754}]} {...lightboxProps}/>)}</WithLightbox>
                <h2>{val0[context.language]??val0.en}</h2>
                <WithLightbox lightbox={<ImageList context={context} _path={newPath} img={[{"name":"cert-TUV.jpg","url":"/upload/element/exprVal/2021/12/7/cert-tuv-1.jpg","mime":"image/jpeg","w":1226,"h":1754}]}/>}>{lightboxProps => (<ImageList context={context} _path={newPath} className="cert-lightbox" img={[{"name":"cert-TUV.jpg","url":"/upload/element/exprVal/2021/12/7/cert-tuv.jpg","mime":"image/jpeg","w":1226,"h":1754}]} {...lightboxProps}/>)}</WithLightbox>
                <ImageList context={context} _path={newPath} img={[{"name":"cert-tuv-sud.png","url":"/upload/element/exprVal/2021/11/22/cert-tuv-sud.png","mime":"image/png","w":203,"h":203}]}/>
            </div>
        </div>
    )
}
export default Slogan
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "div"
        },
        "children": [
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "img"
                }
              },
              "name": [
                {
                  "name": "cert-dekra.png",
                  "url": "/upload/element/exprVal/2021/11/22/cert-dekra.png",
                  "mime": "image/png",
                  "w": 203,
                  "h": 203
                }
              ]
            }
          },
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "img"
                }
              },
              "name": [
                {
                  "name": "cert-DEKRA.jpg",
                  "url": "/upload/element/exprVal/2021/12/7/cert-dekra.jpg",
                  "mime": "image/jpeg",
                  "w": 1226,
                  "h": 1754
                }
              ],
              "attr": {
                "className": "cert-lightbox"
              }
            },
            "children": [
              {
                "data": {
                  "name": "Lightbox"
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "img"
                        }
                      },
                      "name": [
                        {
                          "name": "cert-DEKRA.jpg",
                          "url": "/upload/element/exprVal/2021/12/7/cert-dekra-1.jpg",
                          "mime": "image/jpeg",
                          "w": 1226,
                          "h": 1754
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          },
          {
            "data": {
              "name": "h2",
              "attr": {
                "_i18n": {
                  "it": {
                    "content": "ReNiCo: un partner affidabile!"
                  },
                  "de": {
                    "content": "ReNiCo: ein solider und zuverlässiger Partner!"
                  }
                },
                "content": "ReNiCo: your reliable partner!",
                "_e": {
                  "content": {
                    "is": "exprValExt"
                  }
                }
              }
            }
          },
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "img"
                }
              },
              "name": [
                {
                  "name": "cert-TUV.jpg",
                  "url": "/upload/element/exprVal/2021/12/7/cert-tuv.jpg",
                  "mime": "image/jpeg",
                  "w": 1226,
                  "h": 1754
                }
              ],
              "attr": {
                "className": "cert-lightbox"
              }
            },
            "children": [
              {
                "data": {
                  "name": "Lightbox"
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "img"
                        }
                      },
                      "name": [
                        {
                          "name": "cert-TUV.jpg",
                          "url": "/upload/element/exprVal/2021/12/7/cert-tuv-1.jpg",
                          "mime": "image/jpeg",
                          "w": 1226,
                          "h": 1754
                        }
                      ]
                    }
                  }
                ]
              }
            ]
          },
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "img"
                }
              },
              "name": [
                {
                  "name": "cert-tuv-sud.png",
                  "url": "/upload/element/exprVal/2021/11/22/cert-tuv-sud.png",
                  "mime": "image/png",
                  "w": 203,
                  "h": 203
                }
              ]
            }
          }
        ]
      }
    ]
  }
}
*/
