import { actionMap } from "../conf/actions"
const actions = {
    call: {
        label: "Funcție",
        config: {
            min: 3,
            keys: ["actionName", { is: "expr", label: "Funcție" }, { is: "expr", label: "Arg" }],
        },
    },
    sendMessage: {
        label: "Trimite mesaj",
        config: {
            min: 3,
            max: 3,
            keys: [
                "actionName",
                { is: "string", values: "subscriptions", label: "Mesaj" },
                { is: "expr", label: "Data" },
            ],
        },
    },
    setAttr: {
        label: "Atribut",
        config: {
            min: 3,
            max: 3,
            keys: [
                "actionName",
                { is: "string", label: "Atribut" },
                { is: "expr", label: "Valoare" },
            ],
        },
    },
    setState: {
        label: "Set",
        config: {
            min: 3,
            max: 3,
            keys: [
                "actionName",
                { is: "expr", label: "Atribut" },
                { is: "expr", label: "Valoare" },
            ],
        },
    },
    upsert: {
        label: "Upsert",
        config: {
            min: 4,
            max: 4,
            keys: [
                "actionName",
                { is: "expr", label: "Target" },
                { is: "expr", label: "Condiție" },
                { is: "expr", label: "Valoare" },
            ],
        },
    },
    submit: {
        label: "Trimite",
        config: {
            min: 3,
            max: 3,
            keys: [
                "actionName",
                { is: "string", label: "Nume" },
                { is: "exprVal", label: "Valoare" },
            ],
        },
    },
    ...actionMap,
}
export default actions
