import { buildPath, getType, getKeyType, typeFromTypeDef, getSettings } from "./type"
import { hasField } from "./type-utils"
import apply from "./apply"
import site from "../conf/site"
import { $lineage } from "./symbols"
//import validators from "./validators"
//import converters from "./converters"

const dataCache = new WeakMap()
const getSimple = (o, field, config) => {
    let data
    const cacheable = o && typeof o === "object"
    if (cacheable) {
        data = dataCache.get(o)
        if (Object.keys(data ?? {}).includes(field)) {
            //console.log("get cached", o, field, data[field])
            return data[field]
        }
    }
    const path = buildPath(o, field, config)
    const ret = path?.slice(-1)?.[0]?.value
    if (cacheable) dataCache.set(o, { ...(data ?? {}), [field]: ret })
    return ret
}

const get = (o, field, config) => {
    //console.log("GET", o, field, config)
    if (site.languages) {
        const defaultLanguage = site.languages[0]
        const language = config?.language ?? defaultLanguage
        if (defaultLanguage !== language) {
            const toks = field.split(".")
            const f = [
                ...toks.slice(0, toks.length - 1),
                "_i18n",
                language,
                toks[toks.length - 1],
            ].join(".")
            const ret = getSimple(o, f, config)
            if (typeof ret === "undefined") return getSimple(o, field, config)
            return ret
        }
    }
    return getSimple(o, field, config)
}

const set = (o, field, value, config) => {
    let path = buildPath(o, field, config)
    if (!path) return null
    //console.log("SET", field, value, config, path[path.length - 1].type._t)

    if (site.languages && path[path.length - 1].type._t) {
        const defaultLanguage = site.languages[0]
        const language = config?.language ?? defaultLanguage
        if (defaultLanguage !== language && path[path.length - 1].type._t) {
            const toks = field.split(".")
            const f = [
                ...toks.slice(0, toks.length - 1),
                "_i18n",
                language,
                toks[toks.length - 1],
            ].join(".")
            path = buildPath(o, f, config)
            //console.log("SET1", field, f, path)
        }
    }
    //console.log(path)
    path = path.reduceRight(
        (acc, item) => {
            const update = acc?.[0]?.update
            const value = acc?.[0]?.parent
            const parent = update
                ? update(item.parent, item.parentType, item.key, item.type, value)
                : apply(item.parent, item.parentType, "set", item.key, item.type, value)
            if (typeof parent === "function") return [{ ...item, value, update: parent }, ...acc]
            return [{ ...item, parent, value }, ...acc]
        },
        [{ parent: value }]
    )
    //console.log("SET RESULT", field, value, JSON.stringify(path[0].parent ?? {}, null, " "))
    //console.log("SET PATH", JSON.stringify(buildPath(path[0].parent, field, config), null, "\t"))
    return path[0].parent
}

const unsetRec = (_e, removeKey, i) => {
    if (!_e[removeKey[i]]) return _e
    if (i === removeKey.length - 1)
        return Object.keys(_e)
            .map(k => (/^\d+$/.test(k) ? parseInt(k) : k))
            .filter(k => k !== removeKey[i])
            .reduce(
                (acc, k) =>
                    typeof removeKey[i] === "number" && typeof k === "number" && k > removeKey[i]
                        ? { ...acc, [k - 1]: _e[k] }
                        : { ...acc, [k]: _e[k] },
                {}
            )

    return {
        ..._e,
        [removeKey[i]]: unsetRec(_e[removeKey[i]], removeKey, i + 1),
    }
}

const unset = (o, key, config) => {
    const path = buildPath(o, key, config)
    if (!path) return o
    let lastItem = path[path.length - 1]
    let parentPath = path.slice(0, -1)
    let parentKey = parentPath.length === 0 ? null : parentPath.map(item => item.key).join(".")
    //console.log(o, key, config, path, lastItem, parentKey, parentPath)
    const ret = apply(lastItem.parent, lastItem.parentType, "unset", lastItem.key)
    if (!ret) {
        console.log("NORET", key)
        return o
    }
    const [parent, remove] = ret
    if (!remove) return parentKey ? set(o, parentKey, parent, config) : parent

    let obj = parentKey ? set(o, parentKey, parent, config) : parent
    parentPath = buildPath(obj, parentKey, config)
    let removeKey = remove
    let p = parent
    for (;;) {
        lastItem = parentPath[parentPath.length - 1]
        removeKey = [lastItem.key, ...removeKey]
        parentPath = parentPath.slice(0, -1)
        if (lastItem.parentType[$lineage].includes("map")) {
            //console.log("REMOVEREC", lastItem.parent._e, removeKey)
            p = lastItem.parent._e
                ? {
                      ...lastItem.parent,
                      _e: unsetRec(lastItem.parent._e, removeKey, 0),
                  }
                : lastItem.parent
            break
        }
        if (parentPath.length === 0) return null
    }

    parentKey = parentPath.length === 0 ? null : parentPath.map(item => item.key).join(".")

    //console.log(parentKey, p)
    return parentKey ? set(obj, parentKey, p, config) : p
}

const is = (o, typeName) => getType(o)?.[$lineage]?.includes(typeName)
const impl = (o, typeClass) => getType(o)?.classes.includes(typeClass)
const getCollection = o => getType(o)?.collection
const create = (type, args) => {
    const t = typeFromTypeDef(type)
    //console.log(t)
    if (!t) return null
    const language = args?.language
    const e = apply(null, t, "create", { language })
    //if (t.onCreate) return t.onCreate(e)
    if (args) {
        return Object.keys(args).reduce(
            (acc, field) => (hasField(t, field) ? set(acc, field, args[field]) : acc),
            e
        )
    }
    return e
}
const getNodePath = (entity, language = null) => {
    if (!site.languages) return entity?.path
    const lang = language ?? site.languages?.[0]
    return entity?.path?.filter(p => p.lang === lang)?.[0]?.p
}
const formatField = (o, field, config) => {
    const value = get(o, field, config)
    let parentType = config?.parentType ?? config?.entityInfo ?? config?.parentInfo ?? getType(o)
    const type = getKeyType(field, o, parentType)
    //console.log("FORMAT", field, parentType)
    return apply(value, type, "format", config, o, field)
}
const format = (value, type, config) => apply(value, type, "format", config)

const createValue = (value, type) => {
    if (value?.type)
        return {
            type: "value",
            value,
            _e: { is: value.type, ...(value._c ?? {}) },
        }
    const typeDef = type ?? "t"
    //console.log(value)
    return {
        type: "value",
        value,
        //value: value ? JSON.parse(JSON.stringify(value)) : value,
        _e: { value: typeof typeDef === "string" ? { is: typeDef } : typeDef },
    }
}
const getValue = (o, keyPath, config) => {
    //console.log("GETVALUE", keyPath, o)
    const path = buildPath(o, keyPath, config)
    if (!path || path.length === 0) {
        //console.log("PATHNULL", o, keyPath, config)
        return createValue()
    }
    //if (/itemsMap/.test(keyPath)) console.log(o, keyPath, config, path)
    const { parentType, key, type, value } = path[path.length - 1]
    //console.log(path[path.length - 1])
    /*if (type.classes.includes("setting")) {
        console.log(o, keyPath, config, path)
        }*/
    //console.log("PATH", o, keyPath, config, path)
    const keyConfigInit = type.classes.includes("setting")
        ? { is: type.is, ...(parentType?._e?.[key] ?? {}) }
        : parentType[$lineage].includes("list")
        ? {
              ...(typeof parentType.items === "string"
                  ? { is: parentType.items }
                  : parentType.items),
              ...(parentType[`${key}`] ?? {}),
          }
        : parentType.keys[key]
    const { keyType, ...keyConfig } = keyConfigInit ?? { keyType: null }
    //: { is: type.is, ...(parent?._e?.[key] ?? {}) }
    //if (/itemsMap/.test(keyPath)) console.log("GETVALUE", keyPath, o, value, keyConfig)
    return createValue(value, keyConfig)
}

const getKeyConfig = (o, field, config) => {
    const path = buildPath(o, field, config)
    if (!path || path.length === 0) return "t"

    const { parentType, key, type } = path[path.length - 1]
    //console.log(path[path.length - 1])
    const keyConfigInit = type.classes.includes("setting")
        ? { is: type.is, ...(parentType?._e?.[key] ?? {}) }
        : parentType[$lineage].includes("list")
        ? {
              ...(typeof parentType.items === "string"
                  ? { is: parentType.items }
                  : parentType.items),
              ...(parentType[`${key}`] ?? {}),
          }
        : //: { is: type.is, ...(parent?._e?.[key] ?? {}) }
          parentType.keys[key]
    const { keyType, ...keyConfig } = keyConfigInit
    return keyConfig
}

const setValue = (o, field, value, config) => {
    //console.log("SET1")
    //console.log("SET VALUE", o, field, value, config)
    //const ci = o?.cartItem
    const path = buildPath(o, field, config)
    //console.log("PATH", path)
    //console.log("SET2")
    if (!path || path.length === 0) return null
    const { parentType, key, type } = path[path.length - 1]
    const currentConfigInit = type.classes.includes("setting")
        ? { is: type.is, ...(parentType?._e?.[key] ?? {}) }
        : parentType[$lineage].includes("list")
        ? {
              ...(typeof parentType.items === "string"
                  ? { is: parentType.items }
                  : parentType.items),
              ...(parentType[`${key}`] ?? {}),
          }
        : parentType.keys[key]
    //: { is: type.is, ...(parent?._e?.[key] ?? {}) }
    const { keyType, ...currentConfig } = currentConfigInit ?? { keyType: null }
    const currentKeys = Object.keys(currentConfig)
    const keyConfig = value?._e?.value ?? {}
    const keys = Object.keys(keyConfig)
    const settings = getSettings(type)
    //console.log(settings)
    const addKeys = keys.filter(k => !currentKeys.includes(k) || currentConfig[k] !== keyConfig[k])
    const removeKeys = currentKeys.filter(k => !keys.includes(k))
    //if (addKeys.includes("icon") || removeKeys.includes("icon")) console.trace(addKeys, removeKeys)
    let e = o
    //console.log("SET3")
    //console.log("1", e, addKeys, removeKeys, currentConfig, keyConfig)
    e = removeKeys.reduce((acc, ckey) => {
        if (!settings.includes(ckey)) return acc
        return unset(acc, `${field}.${ckey}`, config)
    }, e)
    //console.log("2", e)
    //console.log("SET4", addKeys)
    e = addKeysRec(e, addKeys, field, keyConfig, config, settings)
    /*addKeys.reduce((acc, ckey) => {
        if (/^\d+$/.test(ckey)) {
            return Object.keys(keyConfig[ckey]).reduce((acc1, ckey1) => {
                const ret = set(acc, `${field}.${ckey}.${ckey1}`, keyConfig[ckey][ckey1], config)
                return ret ?? acc1
            }, acc)
        }
        if (!settings.includes(ckey)) return acc
        const ret = set(acc, `${field}.${ckey}`, keyConfig[ckey], config)
        //console.log(acc, `${field}.${ckey}`, keyConfig[ckey], config, ret)
        return ret
    }, e)*/
    //console.log("3", e)
    //console.log("SET5")
    e = set(e, field, value.value, config)
    //console.log("SET6")
    //console.log("SETVALUE RET", e)
    return e
}

const addKeysRec = (e, addKeys, field, keyConfig, config, settings) => {
    let e1 = e
    if (addKeys.includes("is")) e1 = set(e1, `${field}.is`, keyConfig.is, config)
    if (addKeys.includes("_type")) e1 = set(e1, `${field}._type`, keyConfig._type, config)
    return addKeys.reduce((acc, ckey) => {
        if (/^\d+$/.test(ckey)) {
            return addKeysRec(
                acc,
                Object.keys(keyConfig[ckey]),
                `${field}.${ckey}`,
                keyConfig[ckey],
                config,
                settings
            )
            /*return Object.keys(keyConfig[ckey]).reduce((acc1, ckey1) => {
                const ret = set(acc, `${field}.${ckey}.${ckey1}`, keyConfig[ckey][ckey1], config)
                return ret ?? acc1
            }, acc)*/
        }
        if (!settings.includes(ckey)) return acc
        const ret = set(acc, `${field}.${ckey}`, keyConfig[ckey], config)
        //console.log(acc, `${field}.${ckey}`, keyConfig[ckey], config, ret)
        return ret ?? acc
    }, e1)
}
const appendValue = (o, field, value, config) => {
    //console.log("APP1")
    const v = getValue(o, field, config)
    //console.log("APP2")
    if (!v?.value) return null
    const index = (v.value ?? []).length
    const newVal = {
        ...v,
        value: [...(v.value ?? []), value.value],
        ...(value._e?.value
            ? {
                  _e: {
                      value: {
                          ...(v._e?.value ?? {}),
                          [`${index}`]: value._e?.value,
                      },
                  },
              }
            : {}),
    }
    //console.log("APP3")

    const ret = setValue(o, field, newVal, config)
    //console.log("APP4")
    //console.log(o, field, value, config, newVal, ret)
    return ret
}

export default {
    is,
    impl,
    getCollection,
    get,
    set,
    unset,
    create,
    getPath: getNodePath,
    format,
    formatField,
    buildPath,
    //validate,
    //convert,
    createValue,
    getValue,
    getKeyConfig,
    setValue,
    appendValue,
}
