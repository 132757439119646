import { useAdmin } from "ui/hooks/useAdmin"
import { useSubscription } from "hooks"
import { useMemo } from "react"
import { Link } from "ui/widgets"

const LanguageManager = ({context, _path, fieldPath, fieldEntity, ...props}) => {


    const [node] = useSubscription(["node"])
    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "LanguageManager")

    return (
        <div {...props} ref={ref}  elem-language-manager="">
            {node?.path?.map((item, itemIndex) => (
                <Link context={context} _path={newPath} to={item?.p} key={itemIndex} _index={itemIndex} field-lang="" type-string="">{item?.lang}</Link>
            ))}
        </div>
    )
}
export default LanguageManager
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Map",
          "attr": {
            "target": [
              "f",
              "node.path"
            ],
            "_e": {
              "target": {
                "_type": "op",
                "is": "exprValExt"
              }
            }
          }
        },
        "children": [
          {
            "data": {
              "name": "Link",
              "attr": {
                "content": [
                  "get",
                  "item",
                  "lang"
                ],
                "_e": {
                  "content": {
                    "_type": "op",
                    "is": "exprValExt"
                  },
                  "to": {
                    "_type": "op"
                  }
                },
                "to": [
                  "get",
                  "item",
                  "p"
                ]
              }
            }
          }
        ]
      }
    ]
  },
  "context": {
    "subscriptions": [
      "node"
    ]
  }
}
*/
