import { useAdmin } from "ui/hooks/useAdmin"
import { useCallback, useState, useMemo } from "react"

const InputTextarea = ({context, _path, fieldPath, fieldEntity, value, label, name, dispatch, onChange, inputType, required, rows, ...props}) => {
    const [attrs, setAttr] = useState({})


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "InputTextarea")
    const h0 = useCallback(() => {
        setAttr(attrs => ({...attrs, ["is-focus"]: "1"}))
    }, [])
    const h1 = useCallback(() => {
        setAttr(attrs => ({...attrs, ["is-focus"]: ""}))
    }, [])

    return (
        <div {...props} {...attrs} ref={ref}  elem-input-textarea="" is-empty={(((value ?? "")??[]).length)===0?"":undefined}>
            <label htmlFor={name} field-label="" type-string="">{label}</label>
            <textarea name={name} value={value ?? ""} onFocus={h0} onBlur={h1} onChange={onChange} type={inputType ?? "text"} required={required} rows={rows ?? "5"}/>
        </div>
    )
}
export default InputTextarea
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "is-empty": [
          "if",
          [
            "=",
            [
              "len",
              [
                "??",
                [
                  "get",
                  "attrs",
                  "value"
                ],
                ""
              ]
            ],
            0
          ],
          ""
        ],
        "_e": {
          "is-empty": {
            "1": {
              "1": {
                "1": {
                  "1": {
                    "_type": "op"
                  },
                  "_type": "op"
                },
                "_type": "op"
              },
              "2": {
                "_type": "int"
              },
              "_type": "op"
            },
            "_type": "op",
            "is": "exprValExt"
          }
        }
      }
    },
    "children": [
      {
        "data": {
          "name": "label",
          "attr": {
            "htmlFor": [
              "get",
              "attrs",
              "name"
            ],
            "_e": {
              "htmlFor": {
                "_type": "op"
              },
              "content": {
                "_type": "op"
              }
            },
            "content": [
              "get",
              "attrs",
              "label"
            ]
          }
        }
      },
      {
        "data": {
          "name": "textarea",
          "attr": {
            "name": [
              "get",
              "attrs",
              "name"
            ],
            "_e": {
              "name": {
                "_type": "op",
                "is": "exprValExt"
              },
              "value": {
                "1": {
                  "_type": "op"
                },
                "_type": "op",
                "is": "exprValExt"
              },
              "onFocus": {
                "_type": "action",
                "is": "exprValExt"
              },
              "onBlur": {
                "_type": "action",
                "is": "exprValExt"
              },
              "onChange": {
                "_type": "op",
                "is": "exprValExt"
              },
              "inputType": {
                "1": {
                  "_type": "op"
                },
                "_type": "op",
                "is": "exprValExt"
              },
              "required": {
                "_type": "op",
                "is": "exprValExt"
              },
              "rows": {
                "1": {
                  "_type": "op"
                },
                "_type": "op",
                "is": "exprValExt"
              }
            },
            "value": [
              "??",
              [
                "get",
                "attrs",
                "value"
              ],
              ""
            ],
            "onFocus": [
              "setAttr",
              "is-focus",
              "1"
            ],
            "onBlur": [
              "setAttr",
              "is-focus",
              ""
            ],
            "onChange": [
              "get",
              "attrs",
              "onChange"
            ],
            "inputType": [
              "??",
              [
                "get",
                "attrs",
                "inputType"
              ],
              "text"
            ],
            "required": [
              "get",
              "attrs",
              "required"
            ],
            "rows": [
              "??",
              [
                "get",
                "attrs",
                "rows"
              ],
              "5"
            ]
          }
        }
      }
    ]
  }
}
*/
