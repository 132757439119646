const types = {
    // NODES
    page: {
        keys: {
            header: { is: "img", label: "Imagine Top" },
        },
    },
    menu: {
        is: "entity",
        label: "Meniu",
        collection: "menu",
        _noCreate: true,
        keys: {
            label: { is: "text", label: "Nume" },
            items: { is: "menuItems", label: "Elemente" },
        },
    },
    menuItems: {
        label: "Element",
        is: "list",
        items: "menuItem",
    },
    menuItem: {
        label: "Element Meniu",
        is: "record",
        keys: {
            title: { is: "text", label: "Titlu" },
            p: { is: "text", label: "Cale" },
            items: { is: "menuItems", label: "Elemente" },
        },
    },

    // TYPES
    img: {
        icon: "filled/photo",
        settings: { lightbox: { is: "bool", label: "Lightbox" } },
        renderer: "ImageList",
    },
    link: {
        label: "Legătură",
        icon: "filled/link",
        dynamic: true,
        classes: ["widget"],
        is: "record",
        keys: {
            label: { is: "text", label: "Etichetă" },
            path: { is: "text", label: "Legătură" },
        },
        isBlock: true,
        renderer: "Link",
    },

    block: {
        label: "Container",
        icon: "faBoxOpen",
        is: "map",
        isBlock: true,
        classes: ["widget"],
    },
    bIcon: {
        label: "Iconita cu eticheta",
        icon: "filled/insert_emoticon",
        is: "map",
        classes: ["widget"],
        keys: {
            icon: { is: "icon", label: "Iconita" },
            img: { is: "img", label: "Imagine" },
            text: { is: "text", label: "Text" },
        },
    },
    button: {
        label: "Buton",
        icon: "outlined/smart_button",
        dynamic: true,
        is: "record",
        classes: ["widget"],
        keys: {
            label: { is: "text", label: "Etichetă" },
            message: { is: "text", label: "Mesaj", _t: false },
        },
        renderer: "Button",
    },
    slide: {
        label: "Slide",
        icon: "filled/photo",
        is: "map",
        classes: ["widget"],
        keys: {
            img: { is: "img", label: "Imagine" },
            text: { is: "text", label: "Text" },
            subtitle: { is: "text", label: "Subtitlu" },
        },
    },
    slideshow: {
        label: "Slideshow",
        icon: "filled/photo",
        is: "list",
        classes: ["widget"],
        items: "slide",
    },
    slider: {
        label: "Slider",
        icon: "filled/view_list",
        is: "map",
        isBlock: true,
        //classes: ["widget"],
        keys: {
            //timeout: { is: "number", label: "Interval" },
        },
        //renderer: "Slider",
        _contextFun: "sliderContext",
    },
    category: {
        label: "Category",
        icon: "filled/photo",
        is: "entity",
        collection: "product",
        keys: {
            img: { is: "img", label: "Imagine" },
            title: { is: "text", label: "Nume" },
        },
    },
    product: {
        label: "Product",
        icon: "filled/photo",
        is: "entity",
        collection: "product",
        keys: {
            cat: { is: "ref", ref: "category", label: "Categorie", cache: "title" },
            img: { is: "img", label: "Imagine" },
            title: { is: "text", label: "Nume" },
        },
    },
}
export default types
