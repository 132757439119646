import { useAdmin } from "ui/hooks/useAdmin"
import { useCallback, useMemo } from "react"
import { useLocalState } from "ui/hooks/useLocalState"
import { Link } from "ui/widgets"
import ImageList from "ui/ImageList"
import MainMenu from "./MainMenu"
import LanguageManager from "./LanguageManager"

const Header = ({context, _path, fieldPath, fieldEntity, ...props}) => {


    const [lstate, ldispatch] = useLocalState({menuOpen: false}, {}, [])
    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "Header")
    const val0 = {"en":"/","it":"/it/","de":"/de/"}
    const val1 = {"en":"logo"}
    const h0 = useCallback(() => {
        ldispatch({type:"CHANGE", field: "menuOpen", value: false})
    }, [ldispatch])
    const h1 = useCallback(() => {
        ldispatch({type:"CHANGE", field: "menuOpen", value: !(lstate?._i18n?.[context?.language]?.menuOpen??lstate?.menuOpen)})
    }, [ldispatch,lstate])

    return (
        <header {...props} ref={ref}  elem-header="" id="header">
            <div header-inner="">
                <div header-content="" menu-open={(lstate?._i18n?.[context?.language]?.menuOpen??lstate?.menuOpen)?"1":undefined}>
                    <Link context={context} _path={newPath} to={val0[context.language]??val0.en} id={val1[context.language]??val1.en}>
                        <ImageList context={context} _path={newPath} img={[{"name":"logo.svg","url":"/upload/element/exprVal/2021/11/16/logo.svg"}]}/>
                    </Link>
                    <MainMenu context={context} _path={newPath} onActivate={h0}/>
                    <LanguageManager context={context} _path={newPath}/>
                    <div className="mobile-menu-button" onClick={h1}>
                        <svg
          viewBox="0 0 448 512"
          xmlns="http://www.w3.org/2000/svg"
          
          >
          <path fill="currentColor" d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
        </svg>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header
/*
{
  "layout": {
    "data": {
      "name": "header",
      "attr": {
        "id": "header"
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "header-inner": ""
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "header-content": "",
                "_e": {
                  "header-content": {
                    "is": "exprValExt"
                  },
                  "menu-open": {
                    "1": {
                      "_type": "op"
                    },
                    "_type": "op"
                  }
                },
                "menu-open": [
                  "if",
                  [
                    "f",
                    "state.menuOpen"
                  ],
                  "1"
                ]
              }
            },
            "children": [
              {
                "data": {
                  "name": "Link",
                  "attr": {
                    "_i18n": {
                      "it": {
                        "to": "/it/"
                      },
                      "de": {
                        "to": "/de/"
                      }
                    },
                    "to": "/",
                    "_e": {
                      "to": {
                        "is": "exprValExt"
                      },
                      "id": {
                        "is": "exprValExt"
                      }
                    },
                    "id": "logo"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "img"
                        }
                      },
                      "name": [
                        {
                          "name": "logo.svg",
                          "url": "/upload/element/exprVal/2021/11/16/logo.svg"
                        }
                      ]
                    }
                  }
                ]
              },
              {
                "data": {
                  "name": "MainMenu",
                  "attr": {
                    "onActivate": [
                      "setState",
                      "menuOpen",
                      false
                    ],
                    "_e": {
                      "onActivate": {
                        "2": {
                          "_type": "bool"
                        },
                        "_type": "action"
                      }
                    }
                  }
                }
              },
              {
                "data": {
                  "name": "LanguageManager"
                }
              },
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "mobile-menu-button",
                    "_e": {
                      "className": {
                        "is": "exprValExt"
                      },
                      "onClick": {
                        "2": {
                          "1": {
                            "_type": "op"
                          },
                          "_type": "op"
                        },
                        "_type": "action"
                      }
                    },
                    "onClick": [
                      "setState",
                      "menuOpen",
                      [
                        "!",
                        [
                          "f",
                          "state.menuOpen"
                        ]
                      ]
                    ]
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "solid/faBars"
                    }
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  },
  "context": {
    "state": {
      "menuOpen": false,
      "_e": {
        "menuOpen": {
          "_type": "bool"
        }
      }
    }
  }
}
*/
