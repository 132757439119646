import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import ImageList from "ui/ImageList"
import String from "ui/String"

const BIcon = ({context, _path, fieldPath, fieldEntity, bIcon, config, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        bIcon,
    }), [context, bIcon])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, bIcon, "BIcon")

    return (
        <div {...props} ref={ref}  elem-b-icon="" type-b-icon="">
            <ImageList context={newContext} _path={newPath} fieldPath="img" img={bIcon?.img} elem-img="" type-img="" fieldEntity={bIcon}/>
            <String context={newContext} _path={newPath} fieldPath="text" string={(bIcon?._i18n?.[context?.language]?.text??bIcon?.text)} elem-string="" type-string="" field-text="" fieldEntity={bIcon}/>
        </div>
    )
}
export default BIcon
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e.img"
          ]
        }
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e.text"
          ]
        }
      }
    ]
  }
}
*/
