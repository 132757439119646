import { fieldsRest } from "ui/util"
import { useMemo } from "react"
import ImageList from "ui/ImageList"
import Field from "ui/Field"
import NodeWrap from "./NodeWrap"
import Slogan from "./Slogan"

const Page = ({context, _path, fieldPath, fieldEntity, page, config, node, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        page,
    }), [context, page])
    const newPath = useMemo(() => [..._path, {type:"entity", entity: page}], [_path, page])
    const sf0 = {"title":"text","header":"img"}

    return (
        <NodeWrap context={newContext} _path={newPath} elem-page="" type-page="">
            <div page-header="">
                <ImageList context={newContext} _path={newPath} bg="1" fieldPath="header" img={page?.header} elem-img="" type-img="" fieldEntity={page}/>
                <h1 field-title="" type-text="">{(page?._i18n?.[context?.language]?.title??page?.title)}</h1>
            </div>
            {fieldsRest(page, sf0, context.language)?.map((item, i) => (<Field context={newContext} _path={newPath} fieldPath={item.key} fieldEntity={page} value={item.value} type={item.type} config={item._e} key={i} _index={i}/>))}
            <Slogan context={newContext} _path={newPath}/>
        </NodeWrap>
    )
}
export default Page
/*
{
  "layout": {
    "data": {
      "name": "NodeWrap"
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "page-header": ""
          }
        },
        "children": [
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "op"
                }
              },
              "name": [
                "f",
                "e.header"
              ],
              "attr": {
                "bg": "1"
              }
            }
          },
          {
            "data": {
              "name": "h1",
              "attr": {
                "content": [
                  "f",
                  "e.title"
                ],
                "_e": {
                  "content": {
                    "_type": "op"
                  }
                }
              }
            }
          }
        ]
      },
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e._rest_"
          ]
        }
      },
      {
        "data": {
          "name": "Slogan"
        }
      }
    ]
  }
}
*/
