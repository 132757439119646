//import { getClasses } from "./classes"
//import Type from "./type"
/*
_inc = include classes
_cont = continuation
_ext = extend (override default)
_impl = implement alternate/cascade (if result is null)
*/

let classes
export const registerClasses = classesMap => {
    classes = classesMap
}

const $opConfig = Symbol("opConfig")

const arrayFromNames = name => (typeof name === "string" ? [name] : name)

const getOpConfig = (type, op) => {
    if (type?.[$opConfig]?.[op]) return type[$opConfig][op]
    if (!type) return null
    //const classNames = typeGetClassnames(type?.classes)?.filter(className => classes[className][op])
    //const classNames = Type.classes(type)
    const classNames = type.classes
    if (!classNames?.length) return null

    if (!type[$opConfig]) type[$opConfig] = {}
    type[$opConfig][op] = classNames.reduce((acc, className) => {
        const classImpl = classes[className]
        if (!classImpl || !classImpl[op]) return acc
        if (classImpl._ext) {
            const _ext = arrayFromNames(classImpl._ext).filter(c => classes[c]?.[op])
            if (_ext.length > 0) {
                return { ...acc, _ext: classImpl[op] }
            }
        }
        if (classImpl._impl) {
            const _impl = arrayFromNames(classImpl._impl).filter(c => classes[c]?.[op])
            if (_impl.length > 0) {
                return { ...acc, _impl: [...(acc._impl ?? []), classImpl[op]] }
            }
        }
        if (classImpl._cont) {
            const _cont = arrayFromNames(classImpl._cont).filter(c => classes[c]?.[op])
            if (_cont.length > 0) {
                return { ...acc, _cont: [...(acc._cont ?? []), classImpl[op]] }
            }
        }
        return { ...acc, _op: [...(acc?._op ?? []), classImpl[op]] }
    }, {})
    return type[$opConfig][op]
}

const apply = (value, type, op, ...args) => {
    const opConfig = getOpConfig(type, op)
    //console.log(opConfig)
    if (!opConfig) return null
    //console.log(op, opConfig, opConfig?._op)
    if (opConfig._ext) return opConfig._ext(value, type, ...args)
    if (opConfig._impl) {
        return [...(opConfig._op ?? []), ...opConfig._impl].reduce((acc, op) => {
            if (acc) return acc
            try {
                return op(value, type, ...args)
            } catch (e) {
                console.log(op, typeof op)
                console.log(e)
            }
        }, null)
    }
    if (opConfig._cont) {
        //console.log(opConfig, value, type, args)
        return [...opConfig._op, ...opConfig._cont].reduce(
            (acc, op) => op(acc, type, ...args),
            value
        )
    }
    if (opConfig._op)
        return opConfig._op.reduce((acc, op) => {
            if (acc !== null) return acc
            return op(value, type, ...args)
        }, null)
    return value
}
export default apply
export { getOpConfig }
