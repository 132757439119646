import React from "react"
import { publish } from "hooks/useSubscription"

export const setRec = (state, path, i, value) => {
    if (state === null || typeof state === "undefined") {
        if (/^\d+$/.test(`${path[i]}`)) return [value]
        return {
            [path[i]]: i < path.length - 1 ? setRec(state?.[path[i]], path, i + 1, value) : value,
        }
    }

    if (Array.isArray(state)) {
        const index = typeof path[i] === "number" ? path[i] : parseInt(path[i])
        return [
            ...(state ?? []).slice(0, index),
            i < path.length - 1 ? setRec(state[path[i]], path, i + 1, value) : value,
            ...(state ?? []).slice(index + 1),
        ]
    }

    return {
        ...(state ?? {}),
        [path[i]]: i < path.length - 1 ? setRec(state[path[i]], path, i + 1, value) : value,
    }
}
const reducer = (state, action) => {
    //console.log(state, action)
    switch (action.type) {
        case "CHANGE": {
            return setRec(state, action.field.split("."), 0, action.value)
        }
        case "JOIN": {
            return {
                ...state,
                ...action.newState,
            }
        }
        default:
            return state
    }
}
//export default reducer
const init = ({ initialState, persist }) => {
    if (Object.keys(persist).length === 0 || typeof window === "undefined") return initialState
    return Object.keys(persist).reduce((acc, key) => {
        try {
            const value = window[persist[key]].getItem(key)
            if (value) acc[key] = JSON.parse(value)
        } catch (e) {}
        return acc
    }, initialState)
}

export const useLocalState = (initialState, persist, statePublish, state, dispatch) => {
    const [localState, localDispatch] = React.useReducer(
        reducer,
        { initialState, persist, statePublish },
        init
    )
    const persistValues = React.useRef({})
    const publishValues = React.useRef({})
    React.useEffect(() => {
        if (Object.keys(persist).length === 0) return
        const vals = Object.keys(persistValues.current)
        Object.keys(persist).forEach(key => {
            if (!vals.includes(key)) {
                persistValues.current[key] = localState[key]
                return
            }
            if (persistValues.current[key] === localState[key]) return
            persistValues.current[key] = localState[key]
            try {
                window[persist[key]].setItem(key, JSON.stringify(localState[key]))
            } catch (e) {}
        })
    }, [localState, persist])
    React.useEffect(() => {
        if (statePublish.length === 0) return
        const vals = Object.keys(publishValues.current)
        statePublish.forEach(key => {
            /*if (!vals.includes(key)) {
                publishValues.current[key] = localState[key]
                return
            }*/
            if (publishValues.current[key] === localState[key]) return
            publishValues.current[key] = localState[key]
            publish(key, localState[key])
        })
    }, [localState, persist])
    const proxyDispatch = React.useCallback(
        action => {
            switch (action.type) {
                case "CHANGE": {
                    const key = action.field?.split(".")?.[0]
                    if (
                        Object.keys(initialState).includes(key) ||
                        Object.keys(initialState?._e ?? {}).includes(key) ||
                        !dispatch
                    )
                        return localDispatch(action)
                    //console.log("NOT LOCAL DISPATCH", action, initialState, key)
                    if (dispatch) return dispatch(action)
                    return
                }
                case "JOIN": {
                    return localDispatch(action)
                }
                default:
                    return
            }
        },
        [initialState, dispatch]
    )
    return React.useMemo(
        () => [{ ...(state ?? {}), ...localState }, proxyDispatch],
        [state, localState, proxyDispatch]
    )
}
