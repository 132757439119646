import React from "react"
import { Helmet } from "react-helmet-async"
import C from "../config"
import {getImageUrl} from "ui/util/imgUtil"
import { useLiveValue } from "hooks/useLiveValue"
//import { ld } from "conf/ld"
//import useFinder from "../db/useFinder"

const locales = {
    ro: "ro_RO",
    en: "en_US",
    de: "de_DE",
    it: "it_IT",
}
const getNodePath = (node, language = null) => {
    if (!C.LANGUAGES) return node?.path
    const lang = language ?? C.LANGUAGES[0]
    return node?.path?.filter(p => p.lang === lang)?.[0]?.p
}

//const JsonLd = ({ data }) =>
/*const reduceLdMap = ldMap =>
    ldMap.reduce((acc, entry) => {
        if (typeof entry.value === "string") return { ...acc, [entry.key]: entry.value }
        if (Array.isArray(entry.value)) {
            if (entry._e?.value?._type === "ldList") return { ...acc, [entry.key]: entry.value }
            return { ...acc, [entry.key]: reduceLdMap(entry.value) }
        }
        return acc
    }, {})
/*
const useLd = (node, language) => {
    //const [ldQuery, setLdQuery] = React.useState()
    const [ldRes, ldQuery] = React.useMemo(() => {
        if (!node) return [null, null]
        const ldConf = ld?.[node.type]
        if (!ldConf) return [null, null]
        if (typeof ldConf === "function") {
            //setLdQuery(null)
            return [ldConf(node, language), null]
        }
        return [
            null,
            {
                collection: "ld",
                query: ldConf,
            },
        ]
        //return null
    }, [node, language])
    const [ldResults] = useFinder(ldQuery)

    return React.useMemo(() => {
        if (ldResults) {
            const ldMap = ldResults?.[0]?.map
            if (!ldMap) return null
            return {
                "@context": "http://schema.org",
                ...reduceLdMap(ldMap),
            }
        }
        if (!ldRes) return null
        return { "@context": "http://schema.org", ...ldRes }
    }, [ldRes, ldResults])
}
*/
/*
const getLdIndex = () => (typeof window === "undefined" ? global._db?.ld : window._db?.ld)

const useNodeLoader = path => {
    const db = useLiveValue(getNodeIndex)
    if (!db?.data) return null
    const id = db?.idx?.path?.[path]
    return id ? db?.data?.[id] : nodeNotFound
}
const useLd = (node, language) => {
}*/
const SEO = ({location, node, language}) => {
    console.log("SEO", location.pathname, language, !!node)

    const url = `${C.BASE}${location.pathname}`
    const nodeTitle = node?._i18n?.[language]?.title??node?.title
    const title = C.WEBSITE_NAME + (nodeTitle ? ` - ${nodeTitle}` : "")
    //console.log(url, title)

    
    const seoTitle = node?._seo?._i18n?.[language]?.title??node?._seo?.title??title
    //Entity.get(node, "_seo.title", { language }) || title

    const locale = locales?.[language] ?? "en_US"
    const description = node?._seo?._i18n?.[language]?.description??node?._seo?.description??""
    const ogImage = node?._seo?.image??node?.thumb
    const imageUrl = getImageUrl(ogImage?.[0], "og")
    //console.log("imageurl", imageUrl)
    //if (image) image = imgPath(image, "thumb")
    let meta = [
        { name: "og:url", content: url },
        { name: "og:type", content: "article" },
        { name: "og:title", content: seoTitle },
        { name: "og:description", content: description },
        { name: "og:locale", content: locale },
        { name: "twitter:url", content: url },
        { name: "twitter:card", content: "summary" },
        { name: "twitter:title", content: title },
        { name: "twitter:description", content: description },
        { name: "description", content: description },
    ]
    if (imageUrl)
        meta.push({
            name: "og:image",
            content: imageUrl,
        })

    const htmlAttributes = { lang: language ?? C.LANGUAGES?.[0] }

    let link
    if (C.LANGUAGES) {
        link = [
            ...C.LANGUAGES.map(lang => ({
                rel: "alternate",
                hreflang: lang,
                href: C.BASE + getNodePath(node, lang) + ".html",
            })),
            {
                rel: "alternate",
                hreflang: "x-default",
                href: C.BASE + (node?.alias?.[0] ?? getNodePath(node, C.LANGUAGES[0]) + ".html"),
            },
        ]
    }
    
    //const ld = useLd(node, language)
    const ld = null
    //const script = ld ? { type: "application/ld+json", innerHTML: JSON.stringify(ld) } : undefined
    //console.log(ld)
    /*const ld = {
        "@context": "http://schema.org",
        "@type": "WebSite",
        name: seoTitle,
        url,
        description,
        image,
    }*/
    //if (image) ld.image = image
    //console.log(node)
    //if (!ld)
    return <Helmet title={title} meta={meta} htmlAttributes={htmlAttributes} link={link} />
    /*return (
        <Helmet title={title} meta={meta} htmlAttributes={htmlAttributes} link={link}>
            <script type="application/ld+json">{JSON.stringify(ld)}</script>
        </Helmet>
    )*/
    
    //return null
}
export default SEO
