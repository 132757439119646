import { useCallback, useMemo, useState, useRef, useEffect } from "react"
import { useUnmounted } from "../hooks/useUnmounted"

import { registerQuery, unregisterQuery, execute, executePrio } from "./query"

const isSSR = typeof window === "undefined"
if (!isSSR && !window.dataStore) window.dataStore = {}

const defaultConfig = {
    single: false,
    pageSize: false,
    count: false,
    //setEntityInfo: false,
}
const deepEqual = (a, b) => JSON.stringify(a) === JSON.stringify(b)

const prepareQuery = (query, config) => {
    const { pageSize, count } = config

    //let q = { ...query }
    if (pageSize) {
        const page = config.page || 0
        const skip = page * pageSize
        const limit = pageSize
        return Object.assign({}, query, {
            skip,
            limit,
            count: true,
        })
    }
    if (count) {
        return Object.assign({}, query, {
            count: true,
        })
    }
    return query
}

const resultToData = (res, config) => {
    if (!res) return null
    const results = res.results
    if (config.single) {
        //if (results.length < 1) return null
        //return config.setEntityInfo ? wrapInfo(results[0]) : results[0]
        return results?.[0]
    }
    //return config.setEntityInfo ? results.map(wrapInfo) : results
    //console.log("RESULTS:")
    //console.log(results)
    return results
}

const useQuery = (query, config = defaultConfig) => {
    //console.log("USE QUERY", query, config)
    //console.log(storage, window.dataStore)
    const unmounted = useUnmounted()
    const runningQuery = useRef({})
    const queryId = useRef()
    const state = useRef({})
    const [, triggerRender] = useState(false)

    const onResults = useCallback(
        data => {
            //console.log("onResults", query, config, data)
            //console.log(data)
            if (unmounted.current) return
            const currentQuery = { query, config }
            if (!deepEqual(currentQuery, runningQuery.current)) {
                //console.log("NOT THE SAME", currentQuery, runningQuery)
                return
            }

            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data ? data.total : 0,
            }
            //console.log("RESULTS:", state.current)
            triggerRender(status => !status)
        },
        [query, config, unmounted]
    )

    const waitData = useCallback(
        data => {
            //console.log("waitData", state.current)
            data.then(onResults).catch(error => {
                console.log(error)
                if (unmounted.current) return
                state.current = { status: "error", data: null, total: 0 }
                triggerRender(status => !status)
            })
        },
        [onResults, unmounted]
    )

    useMemo(() => {
        const currentQuery = { query, config }
        //console.log("useMemo", query, config)

        if (deepEqual(currentQuery, runningQuery.current)) return
        runningQuery.current = currentQuery

        if (queryId.current) {
            //console.log("goto unregister 1", queryId.current, query)
            unregisterQuery(queryId.current)
        } //else console.log("nogoto unregister 1")
        queryId.current = null
        if (!query) {
            state.current = {}
            return
        }
        const q = prepareQuery(query, config)
        queryId.current = registerQuery(q, onResults)
        //console.log("QUERY", queryId.current, q)
        //if (queryId.current === "h-1968746725") console.trace("QUERY")
        const data = config.prio ? executePrio(q) : execute(q)
        //if (typeof window !== "undefined") console.log("QUERY EXECUTED", queryId.current, data)
        if (data instanceof Promise) {
            //console.log("QUERY RESULTS LOADING")
            state.current.status = "loading"
            waitData(data, config)
        } else {
            //console.log("QUERY RESULTS DIRECT")
            state.current = {
                status: "loaded",
                data: resultToData(data, config),
                total: data.total,
            }
        }
    }, [query, config, onResults, waitData])

    //useEffect(fetch)
    useEffect(
        () => () => {
            //console.log("goto unregister 2", queryId.current, query)
            if (queryId.current) unregisterQuery(queryId.current)
            queryId.current = null
        },
        []
    )

    //console.log("useQuery", config.tag || query, queryId.current, state.current)
    //console.log(query, state.current)
    return [state.current.data, state.current.status, state.current.total]
}
//export { clearCache, refresh, fetch, execute }
export default useQuery
