//import Type from "entity/type"
//import Data from "entity/data"
//import { $keyOrder } from "entity/symbols"
import { nameAttr } from "./util"

export const fieldsAll = (parent, staticFields, language) => {
    if (!parent) return []
    //console.log(parent)
    //const defaultOrder = Object.keys(parent._e ?? {})
    const o = parent._o ? parent._o.split(",") : Object.keys(parent._e ?? {})
    //if (o.length !== defaultOrder.length) o = defaultOrder
    return o
        .filter(key => parent?.[key] !== undefined && (staticFields[key] || parent?._e?.[key]))
        .map(key => {
            const type =
                staticFields[key] ??
                parent?._e?.[key]?._type?.is ??
                parent?._e?.[key]?._type ??
                parent?._e?.[key]?.is
            return {
                key,
                value: parent?._i18n?.[language]?.[key] ?? parent?.[key],
                type,
                _e: parent?._e?.[key] ?? {},
                attrs: { [nameAttr("field", key)]: "", [nameAttr("type", type)]: "" },
            }
        })
}

export const fieldsRest = (parent, staticFields, language) => {
    if (!parent) return []
    //console.log("REST", parent)
    //const defaultOrder = Object.keys(parent._e ?? {})
    let o = parent._o ? parent._o.split(",") : Object.keys(parent._e ?? {})
    //if (o.length !== defaultOrder.length) o = defaultOrder
    return o
        .filter(key => !staticFields[key] && parent?._e?.[key])
        .map(key => {
            const type =
                parent?._e?.[key]?._type?.is ?? parent?._e?.[key]?._type ?? parent?._e?.[key]?.is
            //console.log(key, type)
            return {
                key,
                value: parent?._i18n?.[language]?.[key] ?? parent?.[key],
                type,
                _e: parent?._e?.[key] ?? {},
                attrs: { [nameAttr("field", key)]: "", [nameAttr("type", type)]: "" },
            }
        })
}
/*
export const fieldsAll = (context, parent) => {
    const t = Type.getType(context, "map")
    const parentType = Type.getKeyType(parent, context, t)
    return parentType?.[$keyOrder].map(key => {
        const field = `${parent}.${key}`
        const fieldType = Type.getKeyType(field, context, t)
        const value = Data.get(context, field)
        const typeName = Type.realType(fieldType)
        return {
            fieldName: key,
            field,
            fieldType,
            typeName,
            value,
        }
    })
}
export const fieldsRest = (context, parent) => {
    const t = Type.getType(context, "map")
    const parentType = Type.getKeyType(parent, context, t)
    return parentType?.[$keyOrder]
        .filter(key => parentType.keys[key]?.keyType === "dynamic")
        .map(key => {
            const field = `${parent}.${key}`
            const fieldType = Type.getKeyType(field, context, t)
            const value = Data.get(context, field)
            const typeName = Type.realType(fieldType)
            return {
                fieldName: key,
                field,
                fieldType,
                typeName,
                value,
            }
        })
}
*/
