import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import Select from "ui/Select"
import Carousel from "ui/Carousel"
import Track from "ui/Track"
import ImageList from "ui/ImageList"
import Navigator from "ui/Navigator"
import Arrows from "ui/Arrows"

const ProductCarousel = ({context, _path, fieldPath, fieldEntity, productCarousel, config, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        productCarousel,
    }), [context, productCarousel])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, productCarousel, "ProductCarousel")

    return (
        <div {...props} ref={ref}  elem-product-carousel="">
            <Select context={newContext} _path={newPath} collection="product" type="product">{(newContext, selectResults, selectTotal) => (<>
                <Carousel context={newContext} _path={newPath} autorun={5}>{(newContext, carousel) => (<>
                    <Track context={newContext} _path={newPath} carousel={carousel}>
                        {newContext.selectResults?.map((item, itemIndex) => (
                            <div key={itemIndex} _index={itemIndex}>
                                <ImageList context={newContext} _path={newPath} fieldPath="img" img={item?.img} elem-img="" type-img="" fieldEntity={item}/>
                            </div>
                        ))}
                    </Track>
                    <Navigator context={newContext} _path={newPath} carousel={carousel}/>
                    <Arrows context={newContext} _path={newPath} carousel={carousel}/>
                </>)}</Carousel>
            </>)}</Select>
        </div>
    )
}
export default ProductCarousel
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Select",
          "attr": {
            "entityType": "product"
          }
        },
        "children": [
          {
            "data": {
              "name": "Carousel",
              "attr": {
                "autorun": 5,
                "_e": {
                  "autorun": {
                    "is": "exprValExt",
                    "_type": "int"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "name": "Track"
                },
                "children": [
                  {
                    "data": {
                      "name": "Map",
                      "attr": {
                        "target": [
                          "f",
                          "selectResults"
                        ],
                        "_e": {
                          "target": {
                            "_type": "op"
                          }
                        }
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "name": "div"
                        },
                        "children": [
                          {
                            "data": {
                              "_e": {
                                "name": {
                                  "_type": "op"
                                }
                              },
                              "name": [
                                "get",
                                "item",
                                "img"
                              ]
                            }
                          }
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                "data": {
                  "name": "Navigator"
                }
              },
              {
                "data": {
                  "name": "Arrows"
                }
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
