import { $lineage, $union, $type } from "./symbols"
import types from "./base"
import { compose } from "./schema"
import apply from "./apply"
//import { buildPath } from "./data"
import settings from "./basic_settings"
import { impl } from "./type-utils"

export const getPath = (o, path) => path?.reduce((v, field) => v?.[field], o)
export const $path = Symbol("path")
export const buildPath = (o, keyPath, config) => {
    //n += 1
    if (o?.[$path]?.[keyPath]) {
        //console.log("O[$path]", keyPath, o, o[$path][keyPath])
        //n2 += 1
        // 5533 4423 3002
        return o[$path][keyPath]
    }
    //const t0 = performance.now()
    //if (!keyPaths[keyPath]) keyPaths[keyPath] = 1
    //else keyPaths[keyPath] += 1

    if (!keyPath) return null
    let parentType = config?.parentType ?? config?.entityInfo ?? config?.parentInfo
    if (!parentType) {
        parentType = getType(o)
        //if (keyPath === "value.attrs.name") console.log(keyPath, o, parentType)
        if (!parentType) return null
    }

    const ret = keyPath
        .toString()
        .split(".")
        .reduce(
            (acc, key) => {
                //n1 += 1
                //if (keyPath === "value.attrs.name") console.log(keyPath, "acc", acc, key)
                if (!acc) return acc
                const last = acc[acc.length - 1]
                if (!last?.type) return acc
                const ret = acc ? apply(last?.value, last?.type, "buildPath", key, acc) : null
                if (!ret) return ret
                //const newLast = ret[ret.length - 1]
                /*if (newLast?.type?._calc) {
                    //console.log("CALC!!!", o, newLast, newLast?.type?._value)
                    const type = newLast?.type

                    //console.log(field, o, config, path, type._value)
                    if (type._value) {
                        const value = calc(
                            createValue(
                                type._value,
                                Type.getKeyType("_value", newLast?.value, type)
                            ),
                            {
                                e: o,
                                parent: newLast?.parent,
                                _e: { e: parentType, parent: newLast?.parentType },
                            }
                        )
                        const t = Type.getKeyType("value", value)
                        Object.defineProperty(t, $calcField, type)

                        //console.log("CALC", value, type, t)

                        return [
                            ...ret.slice(0, -1),
                            {
                                ...newLast,
                                value: value.value,
                                type: t,
                                //type: { ...t, _calc: true, _value: type._value },
                            },
                        ]
                    }
                }
                  */

                return ret
            },
            [{ value: o, type: parentType, entity: o, entityType: parentType }]
        )
    //console.log("BUILDPATH", o, keyPath, ret)
    /*if (!keyPaths1[keyPath]) keyPaths1[keyPath] = performance.now() - t0
    else keyPaths1[keyPath] += performance.now() - t0
    console.log(n, n1, n2, Object.keys(keyPaths).length)
    if (Object.keys(keyPaths).length > 110) {
        console.log(
            Object.entries(keyPaths1)
                .map(kp => [...kp, keyPaths[kp[0]], kp[1] / keyPaths[kp[0]]])
                .sort((a, b) => compare(a[3], b[3]))
        )
        console.log(
            Object.entries(keyPaths1)
                .map(kp => [...kp, keyPaths[kp[0]], kp[1] / keyPaths[kp[0]]])
                .sort((a, b) => compare(a[1], b[1]))
        )
        console.log(Object.values(keyPaths1).reduce((acc, t) => acc + t, 0))
        }*/
    //console.log("BP", o, keyPath, parentType)
    const v = ret?.slice(1)
    if (o && typeof o === "object" && Object.isExtensible(o)) {
        if (!o[$path]) Object.defineProperty(o, $path, { value: {} })
        o[$path][keyPath] = v
        //console.log("SET O", keyPath, o, v)
    }
    return v
}

const simpleGetRec = (target, path, index) => {
    if (typeof target === "undefined" || target === null) return target
    const value =
        Array.isArray(target) && /^\d+$/.test(path[index])
            ? target[parseInt(path[index])]
            : target[path[index]]
    return index === path.length - 1 ? value : simpleGetRec(value, path, index + 1)
}
const simpleGet = (target, key) => simpleGetRec(target, key.split("."), 0)

const checkCond = (cond, target) => {
    if (!target) return false
    return Object.keys(cond).reduce((acc, key) => {
        if (!acc) return acc
        switch (key) {
            case "$in":
                return cond.$in.includes(target)
            case "$not":
                return !checkCond(cond.$not, target)
            case "$eq":
                return cond.$eq === target
            case "$typeof":
                return cond.$typeof === typeof target
            default:
                return cond[key] === simpleGet(target, key)
        }
    }, true)
}
const applyThen = (then, t) =>
    Object.keys(then).reduce((acc, key) => {
        switch (key) {
            case "concat":
                return compose(t, then.concat)
            default:
                return t
        }
    }, t)

const applyAfter = (value, t) => {
    //console.log("APPLYAFTER", value, t)
    if (t?.case) {
        const ret = t.case.reduce((acc, c) => {
            if (acc) return acc
            const { target, cond, ...then } = c
            if (checkCond(c.cond, target ? simpleGet(value, target) : value))
                return applyThen(then, t)
            return acc
        }, null)
        return ret ?? t
    }
    return t
}

const buildTypeDef = (typeInit, value, typeName, union) => {
    let firstRun = false
    let type = typeInit
    let t

    if (!typeName) {
        firstRun = true
        type = typeof typeInit === "string" ? { is: typeInit } : typeInit
        if (!type.is) return null
        t = applyAfter(value, type)
    } else {
        t = { ...applyAfter(value, types[typeName]), [$lineage]: [typeName] }
        if (!t.is || t.is === typeName) {
            if (union)
                return {
                    ...compose(types["union"], t),
                    [$union]: union,
                }
            return t
        }
    }

    let u = union
    if (t.is === "union") {
        u = [...(union ?? []), t.types ?? []]
        const level = u.length - 1
        let _type = type._type?.is ? type._type.is : type._type // correct obj {is}
        _type = (typeof _type === "string" ? [_type] : _type)?.[level]
        if (!_type) {
            _type = u[level][0]
            if (typeof _type !== "string") _type = _type?.is
        }
        if (_type) {
            const realType =
                u[level].length === 0
                    ? _type
                    : u[level].filter(lt =>
                          typeof lt === "string" ? lt === _type : lt.is === _type
                      )?.[0] ?? _type
            let rt = typeof realType === "string" ? { is: realType } : realType
            t = compose(t, applyAfter(value, rt))
            if (firstRun) type = t
        }
    }
    return compose(buildTypeDef(type, value, t.is, u), t)
}

export const renameKeys = conf => {
    return Object.keys(conf).reduce((acc, key) => {
        if (!conf[key]) return acc
        if (typeof conf[key] === "string") return { ...acc, [key]: conf[key] }
        const { type, name, ...rest } = conf[key]
        if (type) return { ...acc, [key]: { is: type, ...rest } }
        return { ...acc, [key]: conf[key] }
    }, {})
}
const stringTypeDefCache = {}
const objTypeDefCache = new WeakMap()
/*let n = 0
const req = {}*/
export const typeFromTypeDef = typeDef => {
    if (!typeDef) return null
    const isString = typeof typeDef === "string"
    if (isString) {
        if (stringTypeDefCache[typeDef]) return stringTypeDefCache[typeDef]
    } else {
        const t = objTypeDefCache.get(typeDef)
        if (typeof t !== "undefined") return t
    }

    let t = buildTypeDef(isString ? { is: typeDef } : typeDef)

    if (t) t = apply(undefined, t, "typeKeys")
    if (isString) stringTypeDefCache[typeDef] = t
    else objTypeDefCache.set(typeDef, t)
    if (!t) {
        console.log("No type info for ", typeDef)
    }
    return t
}
//const printReq = req => Object.entries(req).sort((a, b) => compare(a[1], b[1]))

export const getType = (o, typeHint) => {
    //console.log("GETTYPE1", o, typeHint)
    if (o?.[$type]) {
        return o[$type]
    }
    /*const key = JSON.stringify({ o, typeHint })
    if (!req[key]) req[key] = 1
    else req[key] += 1
    n += 1
    console.log(n, Object.keys(req).length, printReq(req))
    //5734 1654
    //3051 1483*/

    let typeDef = typeof typeHint === "string" ? { is: typeHint } : typeHint
    if (o?.type) {
        typeDef = {
            ...(typeDef ?? {}),
            ...(o._c ?? {}),
            is: o.type,
        }
    }
    if (!typeDef) {
        //console.log("GETTYPE RES NULL", o, typeHint)
        return null
    }
    //console.log("GETTYPE2", o, typeDef)
    let t = buildTypeDef(typeDef, o)
    if (t) t = apply(o, t, "typeKeys")
    if (o && typeof o === "object" && Object.isExtensible(o) && !o?.[$type]) {
        Object.defineProperty(o, $type, { value: t })
    }

    if (!t) {
        console.log("No type info for ", o, typeHint)
    }
    return t
}
//let n = 0
//let n1 = 0
export const getKeyType = (key, parent, parentType) => {
    //n += 1
    //console.log(n, n1)
    //console.log("GET", o, parent, parentType)
    if (typeof key !== "string" && typeof key !== "number") return null

    if (!parentType) {
        if (parent) {
            const pType = getType(parent)
            if (!pType) return null
            return getKeyType(key, parent, pType)
        }
    }
    //console.log("GETKEYTYPE", key, parent, buildPath(parent, key, { parentType }))
    const ret = buildPath(parent, key, { parentType })?.slice(-1)?.[0]?.type ?? null
    //console.log(o, parent, parentType, ret)
    return ret
}

const compare = (s1, s2) => (s1 < s2 ? -1 : s1 > s2 ? 1 : 0)

let entityTypes
export const getEntityTypes = () => {
    if (entityTypes) return entityTypes
    entityTypes = Object.keys(types)
        .filter(t => impl(typeFromTypeDef(t), "entity"))
        .sort((t1, t2) => compare(types[t1]?.label, types[t2]?.label))
    return entityTypes
}
//const settingsCache = new WeakMap()
export const getSettings = t => {
    if (!t) return null
    //console.log(t)
    try {
        return [
            ...Object.keys(t?.settings ?? {}),
            ...t.classes?.reduce(
                (acc, className) => [...acc, ...Object.keys(settings[className] ?? {})],
                []
            ),
        ]
    } catch (e) {
        throw new Error(`Error getSettings` + JSON.stringify(t))
    }
}
/*export default {
    typeFromTypeDef,
    getType,
    getKeyType,
    getEntityTypes,
    getSettings,
}*/
