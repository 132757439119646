import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import NodeTransition from "ui/NodeTransition"
import Header from "./Header"
import Footer from "./Footer"

const Site = ({context, _path, fieldPath, fieldEntity, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "Site")

    return (
        <div {...props} ref={ref}  elem-site="">
            <Header context={context} _path={newPath}/>
            <main>
                <NodeTransition context={context} _path={newPath} main-inner=""/>
            </main>
            <Footer context={context} _path={newPath}/>
        </div>
    )
}
export default Site
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Header"
        }
      },
      {
        "data": {
          "name": "main"
        },
        "children": [
          {
            "data": {
              "name": "NodeTransition",
              "attr": {
                "main-inner": ""
              }
            }
          }
        ]
      },
      {
        "data": {
          "name": "Footer"
        }
      }
    ]
  }
}
*/
