import React from "react"
import ReactDOM from "react-dom"
import { Link, FaIcon, LazyImage } from "./widgets"
import { useAdmin } from "./hooks/useAdmin"
//Field,
//EntityView,
//   useQuery,
//    useIntersection,
//import { useUnmounted } from "../hooks/useUnmounted"
//} from "lib"

//import elements from "elements"

import { BLOCK_TAGS, MARK_TAGS } from "./util/htmlTags"

const ImageElement = ({ node, other, info }) => {
    return (
        <div>
            {node.src.map((src, i) => (
                <LazyImage key={i} src={src} alt="" />
            ))}
            {renderChildren(node, other, info)}
        </div>
    )
}
/*const FieldElement = ({ node, other, info }) => (
    <Field info={{ ...info, value: undefined }} field={`meta.${node.field}`} display="embed" />
)*/
const Element = () => null
const FieldElement = ({ node, info }) => (
    <Element info={info} field={`meta.${node.field}`} display="embed" />
)

const useEmbed = () => {
    // id
    /*const q = React.useMemo(
        () =>
            id
                ? {
                      query: { _id: id },
                      collection: "embed",
                  }
                : null,
        [id]
    )
    const [data] = useQuery(q)
    //console.log(id, q, data)
    return data?.[0]*/
    return []
}
const EmbedElement = ({ node, info }) => {
    const [style, setStyle] = React.useState({})
    const domRef = React.useRef()
    const embed = useEmbed(node?.id)
    const portal = node?.portal
    React.useEffect(() => {
        if (!portal) return
        const rect = domRef?.current?.getBoundingClientRect()
        //console.log(rect)
        setStyle({ top: rect.top })
    }, [portal])
    const className = `embed ${node?._class ?? ""}`
    const visibility = info.fieldInfo._child_visibility
    if (portal) {
        const container = typeof window !== "undefined" ? document.getElementById(portal) : null
        const attrs = { className, visibility, style }
        return (
            <div ref={domRef} className="beacon">
                {typeof window !== "undefined" && container
                    ? ReactDOM.createPortal(
                          <Element info={info} entity={embed} display="embed" attrs={attrs} />,
                          container
                      )
                    : null}
            </div>
        )
    }
    const attrs = { className, visibility }
    return <Element info={info} entity={embed} display="embed" attrs={attrs} />
}

const renderChildren = (node, other, info) => {
    return node?.children?.map((node, i) => renderNode(node, i, other, info))
}

const Block = ({ Tag, node, other, info }) => {
    const domRef = React.useRef()
    /*const visible = React.useRef()
    const unmounted = useUnmounted()

    const onIntersect = React.useCallback(
        entry => {
            if (unmounted.current || visible.current || !entry.isIntersecting) return
            visible.current = true
            domRef.current.classList.add("visible")
        },
        [visible, domRef, unmounted]
    )*/
    const { type, children, ...attrs } = node
    //useIntersection(info?.fieldInfo?._child_visibility ? domRef : null, onIntersect)
    //console.log(Tag, attrs, node)
    return (
        <Tag ref={domRef} {...attrs}>
            {renderChildren(node, other, info)}
        </Tag>
    )
}

const renderText = node => {
    if (node.text)
        return node.text.split("\n").map((item, i) =>
            i > 0 ? (
                <React.Fragment key={i}>
                    <br />
                    {item}
                </React.Fragment>
            ) : (
                <React.Fragment key={i}>{item}</React.Fragment>
            )
        )
}

const addMark = (Mark, content) => <Mark>{content}</Mark>
const renderLeaf = (node, i) => {
    let ret = renderText(node)
    if (node.inlineStyle) ret = <span style={node.inlineStyle}>{ret}</span>
    ret = Object.keys(MARK_TAGS).reduce(
        (acc, mark) => (node[mark] ? addMark(MARK_TAGS[mark], acc) : acc),
        ret
    )
    return <React.Fragment key={i}>{ret}</React.Fragment>
}

const renderNode = (node, i, other, info) => {
    //console.log(node.type, node)
    if (!node.type) return renderLeaf(node, i)

    if (BLOCK_TAGS[node.type])
        return <Block key={i} Tag={BLOCK_TAGS[node.type]} node={node} other={other} info={info} />
    switch (node.type) {
        case "image":
            return <ImageElement key={i} node={node} other={other} info={info} />
        case "field":
            return <FieldElement key={i} node={node} other={other} info={info} />
        case "embed":
            return <EmbedElement key={i} node={node} other={other} info={info} />
        case "link": {
            const attrs = {}
            if (node.target) attrs["target"] = node.target
            if (node.className) attrs["className"] = node.className
            if (node.iconBefore) attrs["iconBefore"] = node.iconBefore
            if (node.iconAfter) attrs["iconAfter"] = node.iconAfter
            return (
                <Link key={i} to={node.url} {...attrs}>
                    {renderChildren(node, other, info)}
                </Link>
            )
        }
        case "span": {
            const { type, children, ...attrs } = node
            return (
                <span key={i} {...attrs}>
                    {renderChildren(node, other, info)}
                </span>
            )
        }
        case "icon":
            return (
                <span key={i} className="icon">
                    <FaIcon icon={node.icon} />
                </span>
            )
        case "iframe":
            return <iframe key={i} title={node.title ?? ""} {...node.attrs}></iframe>
        default:
            console.log(node.type, node)
            return <Block Tag="p" key={i} node={node} other={other} info={info} />
    }
}

const HTML = ({ context, _path, fieldPath, fieldEntity, value, html, config, ...other }) => {
    const v = value ?? html
    const [ref] = useAdmin(context, _path, fieldPath, fieldEntity, v)
    //console.log("HTML", v, config, other)
    //console.log("HTML", fieldPath, fieldEntity, context)
    //const value = context.e
    if (!v || !Array.isArray(v)) return null
    //console.log("HTML", field, "in", value, info, other)
    const ret = v.map((node, i) => renderNode(node, i, {}, config))
    //console.log(ret)
    return (
        <div ref={ref} {...other} elem-html="" type-html="">
            {ret}
        </div>
    )
}
export default HTML
