import React from "react"
import { useSubscriptionProvider } from "hooks/useSubscription"

export const useAdmin = (context, _path, field, entity, value, element) => {
    const domRef = React.useRef()
    const mouseOver = React.useRef()
    const newPath = React.useRef(_path)
    const setFieldAdmin = React.useRef(useSubscriptionProvider("fieldAdmin"))
    //console.log("UseAdmin", context, _path, field, entity, value)
    React.useEffect(() => {
        //console.log(context)
        if (!context?.user) return
        const onMouseOver = e => {
            //console.log("OVER", e.target, domRef.current)
            e.stopPropagation()
            if (mouseOver.current) return
            mouseOver.current = true
            setFieldAdmin.current({ mouse: "enter", fieldLine: newPath.current })
        }
        const onMouseLeave = e => {
            //console.log("LEAVE", e.target, domRef.current)
            //e.stopPropagation()
            //if (e.target !== domRef.current) return
            window.requestAnimationFrame(() => {
                mouseOver.current = false
                setFieldAdmin.current({ mouse: "leave", fieldLine: newPath.current })
            })
        }
        if (domRef.current) {
            domRef.current.addEventListener("mouseover", onMouseOver)
            domRef.current.addEventListener("mouseleave", onMouseLeave)
        } else {
            console.log(context, field, entity, value)
        }
        return () => {
            setFieldAdmin.current({ mouse: "leave", fieldLine: newPath.current })
            if (domRef.current) {
                domRef.current.removeEventListener("mouseover", onMouseOver)
                domRef.current.removeEventListener("mouseleave", onMouseLeave)
            }
        }
    }, [context?.user, setFieldAdmin])
    return React.useMemo(() => {
        if (!context?.user) return [domRef, _path, {}]
        newPath.current = _path
        if (field) {
            newPath.current = [
                ..._path,
                { domRef, type: "field", field, entity, value, ...(element ? { element } : {}) },
            ]
        } else {
            if (element) newPath.current = [..._path, { domRef, type: "element", element }]
        }
        return [domRef, newPath.current] // onMouseOver, onMouseLeave }
    }, [_path, field, entity, value])
    //return [ref, newPath]
}
