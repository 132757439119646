import React from "react"
import { useDb } from "../../db/useDb"

export const useSelection = (collection, type, cond, limit) => {
    const db = useDb()

    return React.useMemo(() => {
        let selectTotal = 0
        let selectResults = []
        if (!collection || !db?.[collection]?.data) return [selectResults, selectTotal]

        const coll = db[collection].data
        selectResults = Object.values(coll).filter(doc => {
            if (limit > 0 && selectTotal >= limit) return false
            if (doc.type !== type) return false
            if (cond) {
                const conds = typeof cond === "function" ? cond() : cond
                for (let f of conds) {
                    const { a, v } = f
                    const toks = a.split(".")
                    const val = toks.reduce((acc, field) => acc?.[field], doc)
                    //console.log(a, val, v)
                    if (val !== v) return false
                }
            }
            selectTotal++
            return true
        })

        return [selectResults, selectTotal]
    }, [db, collection, type, cond, limit])
}
