import C from "../../config";

/*const afterBuildPath = (path, type) => {
    const language = config?.language ?? C.LANGUAGES[0]
    const defaultLanguage = config?.defaultLanguage ?? C.LANGUAGES[0]
    return type._t === false || language === defaultLanguage
        ? path
        : [...path.slice(0, path.length - 1), "_i18n", language, path[path.length - 1]]
}*/
const create = (o, type, args) => ({
  ...o,
  _lang: args?.language ?? C.LANGUAGES[0]
});

export default {
  _cont: ["create"], //, "afterBuild", "path"
  create
  //afterBuildPath,
};
