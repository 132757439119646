//import types from "./base"
//import Type from "./type"
export default {
    type: {
        _hidden: { is: "bool", label: "Ascuns" },
        _calc: { is: "bool", label: "Automat" },
        _value: { is: "expr", label: "Valoare automată", optional: true },
        _e: { is: "map" },
        _t: { is: "bool", label: "Translatabil" },
        _layout: {
            is: "displayNode",
            optional: true,
            label: "Layout",
            editor: { nocollapse: true, widget: "tree" },
        },
        _context: { is: "displayContext", optional: true },
        label: { is: "string", label: "Etichetă" },
        is: { is: "string", label: "Tip Date" },
        types: { is: "list", label: "Tipuri acceptate" },
        _type: { is: "typeInfo" },
    },
    node: {
        path: {
            is: "path",
            _sin: true,
            label: "Cale",
            _concat: { readOnly: { not: { get: "pathauto" } } },
        },
        pathauto: {
            is: "bool",
            label: "Cale automată",
            default: true,
        },
        alias: {
            is: "list",
            items: "string",
            label: "Alias",
        },
        pathinfo: { is: "string", label: "Format cale" },
        _seo: { is: "seo", _sin: true },
    },
    entity: {
        _id: { is: "string", _sin: true, label: "ID", readOnly: true },
        type: { is: "string", _sin: true, label: "Tip", values: "entityOptions" },
        _created: { is: "date", showTime: true, _sin: true, label: "Data creării", readOnly: true },
        _updated: {
            is: "date",
            showTime: true,
            _sin: true,
            label: "Data ultimei actualizări",
            readOnly: true,
        },
        _pub: { is: "bool", _sin: true, label: "Publicat" },
    },
    map: {
        _e: { is: "map", _sin: true },
        _o: { is: "string", _sin: true },
        _sin: "bool", // setting is inside map, not in parents' config _e
        _forbidden: "stringList", // forbidden key names
        _i18n: { is: "map", values: "map", _sin: true },
        //keyOrder: { is: "list", volatile: true },
        //values: "t",
    },
    widget: {
        _nodel: {
            is: "bool",
            label: "Nu se poate șterge",
            widget: "toggle",
            require: "admin",
        },
        //is: { is: "string", label: "Tip", valuesFun: "typeOptions", require: "admin" },
        id: { is: "text", label: "CSS ID" },
        _class: { is: "text", label: "Clasa CSS" },
        /*_hidden: {
            is: "bool",
            label: "Ascuns",
            widget: "toggle",
            require: "admin",
            _hidden: true,
        },*/
        _invisible: {
            is: "bool",
            label: "Invizibil",
            widget: "toggle",
        },
        //_label: { is: "text", label: "Etichetă" },
        //_visibility: { is: "bool", label: "CSS: Marchează vizibilitatea" },
        /*_child_visibility: {
            is: "bool",
            label: "CSS: Marchează vizibilitatea pentru descendenti",
        },
        _renderer: { is: "text", label: "Renderer", require: "admin" },
        renderChildren: { is: "bool", label: "Render recursive", require: "admin" },*/
        /*region: { is: "text", label: "Regiune" },
        _inner: { is: "bool", label: "Container interior" },
        _inner2: { is: "bool", label: "Container interior dublu" },
        state: {
            is: "select",
            label: "Stare",
            widget: "checkbox",
            multiple: true,
            values: [],
            _hidden: true,
        },
        _nowrap: { is: "bool", label: "Fără container interior" },*/
    },
}
