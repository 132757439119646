import { useAdmin } from "ui/hooks/useAdmin"
import { fieldsRest } from "ui/util"
import { useMemo } from "react"
import Field from "ui/Field"

const Block = ({context, _path, fieldPath, fieldEntity, block, config, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        block,
    }), [context, block])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, block, "Block")
    const sf0 = {}

    return (
        <div {...props} ref={ref}  elem-block="" type-block="">
            <div block-inner="">
                {fieldsRest(block, sf0, context.language)?.map((item, i) => (<Field context={newContext} _path={newPath} fieldPath={item.key} fieldEntity={block} value={item.value} type={item.type} config={item._e} key={i} _index={i}/>))}
            </div>
        </div>
    )
}
export default Block
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "block-inner": ""
          }
        },
        "children": [
          {
            "data": {
              "_e": {
                "name": {
                  "_type": "op"
                }
              },
              "name": [
                "f",
                "e._rest_"
              ]
            }
          }
        ]
      }
    ]
  }
}
*/
