import type from "./type"
import ref from "./ref"
import entity from "./entity"
import i18n from "./i18n"
import map from "./map"
import list from "./list"
import create from "./create"
import classes from "../../conf/classes"
import { registerClasses } from "../apply"

const classesMap = {
    type,
    ref,
    create,
    entity,
    i18n,
    map,
    list,
    ...classes,
}
export const initClasses = () => {
    registerClasses(classesMap)
}
