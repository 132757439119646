import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"
import { Link } from "ui/widgets"
import ImageList from "ui/ImageList"
import HTML from "ui/HTML"

const Footer = ({context, _path, fieldPath, fieldEntity, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "Footer")
    const html0 = [{"type":"paragraph","children":[{"text":"sc "},{"text":"ReNiCo Ind","bold":true},{"text":" srl"}]}]
    const html1 = [{"type":"paragraph","children":[{"text":"Simpozionului 3 St.\n420156 Bistrita (BN)\nROMANIA (RO)\nEUID: ROONRC J06/149/2000\nC.F. (VAT) RO 12995340"}]}]
    const html2 = [{"type":"paragraph","children":[{"text":"Phone: +40 (0) 363 401 237\nPhone: +40 (0) 363 401 238\nFax: +40 (0) 363 401 239\nMobile: +39 333 151 5086 (IT)\nMobile: +40 771 751 371 (RO)"}]}]

    return (
        <footer {...props} ref={ref}  elem-footer="" id="footer">
            <div footer-inner="">
                <div className="company">
                    <ImageList context={context} _path={newPath} className="logo" img={[{"name":"logo.svg","url":"/upload/element/exprVal/2021/11/16/logo-1.svg"}]}/>
                    <div><HTML html={html0}/></div>
                </div>
                <div className="contact">
                    <div className="address">
                        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          
          >
          <path fill="currentColor" d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5a2.5 2.5 0 0 1 0-5a2.5 2.5 0 0 1 0 5z" />
        </svg>
                        <div><HTML html={html1}/></div>
                    </div>
                    <div className="phone">
                        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          
          >
          <path fill="currentColor" d="M6.62 10.79c1.44 2.83 3.76 5.14 6.59 6.59l2.2-2.2c.27-.27.67-.36 1.02-.24c1.12.37 2.33.57 3.57.57c.55 0 1 .45 1 1V20c0 .55-.45 1-1 1c-9.39 0-17-7.61-17-17c0-.55.45-1 1-1h3.5c.55 0 1 .45 1 1c0 1.25.2 2.45.57 3.57c.11.35.03.74-.25 1.02l-2.2 2.2z" />
        </svg>
                        <div><HTML html={html2}/></div>
                    </div>
                    <div className="email">
                        <svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          
          >
          <path fill="currentColor" d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10h5v-2h-5c-4.34 0-8-3.66-8-8s3.66-8 8-8s8 3.66 8 8v1.43c0 .79-.71 1.57-1.5 1.57s-1.5-.78-1.5-1.57V12c0-2.76-2.24-5-5-5s-5 2.24-5 5s2.24 5 5 5c1.38 0 2.64-.56 3.54-1.47c.65.89 1.77 1.47 2.96 1.47c1.97 0 3.5-1.6 3.5-3.57V12c0-5.52-4.48-10-10-10zm0 13c-1.66 0-3-1.34-3-3s1.34-3 3-3s3 1.34 3 3s-1.34 3-3 3z" />
        </svg>
                        <div>
                            <Link context={context} _path={newPath} to="mailto:info@renicoind.com">info@renicoind.com</Link>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
export default Footer
/*
{
  "layout": {
    "data": {
      "name": "footer",
      "attr": {
        "id": "footer"
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "footer-inner": ""
          }
        },
        "children": [
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "company"
              }
            },
            "children": [
              {
                "data": {
                  "_e": {
                    "name": {
                      "_type": "img"
                    }
                  },
                  "name": [
                    {
                      "name": "logo.svg",
                      "url": "/upload/element/exprVal/2021/11/16/logo-1.svg"
                    }
                  ],
                  "attr": {
                    "className": "logo"
                  }
                }
              },
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "content": [
                      {
                        "type": "paragraph",
                        "children": [
                          {
                            "text": "sc "
                          },
                          {
                            "text": "ReNiCo Ind",
                            "bold": true
                          },
                          {
                            "text": " srl"
                          }
                        ]
                      }
                    ],
                    "_e": {
                      "content": {
                        "_type": "html"
                      }
                    }
                  }
                }
              }
            ]
          },
          {
            "data": {
              "name": "div",
              "attr": {
                "className": "contact"
              }
            },
            "children": [
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "address"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "filled/place"
                    }
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "content": [
                          {
                            "type": "paragraph",
                            "children": [
                              {
                                "text": "Simpozionului 3 St.\n420156 Bistrita (BN)\nROMANIA (RO)\nEUID: ROONRC J06/149/2000\nC.F. (VAT) RO 12995340"
                              }
                            ]
                          }
                        ],
                        "_e": {
                          "content": {
                            "_type": "html",
                            "is": "exprValExt"
                          }
                        }
                      }
                    }
                  }
                ]
              },
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "phone"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "filled/phone"
                    }
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "content": [
                          {
                            "type": "paragraph",
                            "children": [
                              {
                                "text": "Phone: +40 (0) 363 401 237\nPhone: +40 (0) 363 401 238\nFax: +40 (0) 363 401 239\nMobile: +39 333 151 5086 (IT)\nMobile: +40 771 751 371 (RO)"
                              }
                            ]
                          }
                        ],
                        "_e": {
                          "content": {
                            "_type": "html",
                            "is": "exprValExt"
                          }
                        }
                      }
                    }
                  }
                ]
              },
              {
                "data": {
                  "name": "div",
                  "attr": {
                    "className": "email"
                  }
                },
                "children": [
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "icon"
                        }
                      },
                      "name": "filled/alternate_email"
                    }
                  },
                  {
                    "data": {
                      "name": "div",
                      "attr": {}
                    },
                    "children": [
                      {
                        "data": {
                          "name": "Link",
                          "attr": {
                            "to": "mailto:info@renicoind.com",
                            "_e": {
                              "to": {
                                "is": "exprValExt"
                              },
                              "content": {
                                "is": "exprValExt"
                              }
                            },
                            "content": "info@renicoind.com"
                          }
                        }
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
