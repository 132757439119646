import axios from "axios"
import {getLanguage} from "history/history"
//import calc from "entity/calc"
//import Type from "../../entity/type"
//import Data from "../../entity/data"

const languages = {en: "Engleza", de: "Germana", it: "Italiana"}
export const submit = (name, value) => {
    /*
    //attr, attrInfo, args, info, dispatch, multiple) => {
    const name = attr[1]
    //const t = Type.getKeyType(2, attr, attrInfo)
    const value = calc(Data.getValue(attr, 2, { parentType: attrInfo }), info.context)
    const valueType = Type.realType(Type.getKeyType("value", value))
    */
    const language = languages[getLanguage(document.location.pathname)]
    const submission = {
        type: "submission",
        kind: name,
        language,
        payload: value,
        _e: {
            payload: { is: "attrList" },
        },
    }
    //console.log("SUBMIT", attr, attrInfo, args, info, name, value)
    return axios
        .post("form", { submission })
        .then(response => {
            console.log(response)
            //if (multiple) return info
        })
        .catch(error => {
            console.log(error)
            //if (multiple) return info
        })
}
//export {submit}
