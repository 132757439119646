import { useAdmin } from "ui/hooks/useAdmin"
import { Fragment, useMemo } from "react"
import Select from "ui/Select"
import ImageList from "ui/ImageList"
import String from "ui/String"
import CategoryProducts from "./CategoryProducts"

const CategoryList = ({context, _path, fieldPath, fieldEntity, categoryList, config, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        categoryList,
    }), [context, categoryList])
    const [ref, newPath] = useAdmin(newContext, _path, fieldPath, fieldEntity, categoryList, "CategoryList")

    return (
        <div {...props} ref={ref}  elem-category-list="">
            <Select context={newContext} _path={newPath} collection="product" type="category">{(newContext, selectResults, selectTotal) => (<>
                {newContext.selectResults?.map((item, itemIndex) => (<Fragment key={itemIndex}>
                    <h2 className="category-title">
                        <div className="icon">
                            <ImageList context={newContext} _path={newPath} inline="1" _wrap="1" fieldPath="img" img={item?.img} elem-img="" type-img="" fieldEntity={item}/>
                        </div>
                        <String context={newContext} _path={newPath} fieldPath="title" string={(item?._i18n?.[context?.language]?.title??item?.title)} elem-string="" type-string="" fieldEntity={item}/>
                    </h2>
                    <CategoryProducts context={newContext} _path={newPath} cat={item?._id}/>
                </Fragment>))}
            </>)}</Select>
        </div>
    )
}
export default CategoryList
/*
{
  "layout": {
    "data": {
      "name": "div"
    },
    "children": [
      {
        "data": {
          "name": "Select",
          "attr": {
            "entityType": "category"
          }
        },
        "children": [
          {
            "data": {
              "name": "Map",
              "attr": {
                "target": [
                  "f",
                  "selectResults"
                ],
                "_e": {
                  "target": {
                    "_type": "op"
                  }
                }
              }
            },
            "children": [
              {
                "data": {
                  "name": "h2",
                  "attr": {
                    "className": "category-title",
                    "_e": {
                      "className": {
                        "is": "exprValExt"
                      }
                    }
                  }
                },
                "children": [
                  {
                    "data": {
                      "name": "div",
                      "attr": {
                        "className": "icon"
                      }
                    },
                    "children": [
                      {
                        "data": {
                          "_e": {
                            "name": {
                              "_type": "op"
                            }
                          },
                          "name": [
                            "get",
                            "item",
                            "img"
                          ],
                          "attr": {
                            "inline": "1",
                            "_e": {
                              "inline": {
                                "is": "exprValExt"
                              }
                            },
                            "_wrap": "1"
                          }
                        }
                      }
                    ]
                  },
                  {
                    "data": {
                      "_e": {
                        "name": {
                          "_type": "op"
                        }
                      },
                      "name": [
                        "get",
                        "item",
                        "title"
                      ]
                    }
                  }
                ]
              },
              {
                "data": {
                  "name": "CategoryProducts",
                  "attr": {
                    "cat": [
                      "get",
                      "item",
                      "_id"
                    ],
                    "_e": {
                      "cat": {
                        "_type": "op"
                      }
                    }
                  }
                }
              }
            ]
          }
        ]
      }
    ]
  }
}
*/
