import axios from "axios"
import React from "react"
import { useUnmounted } from "hooks/useUnmounted"

const cache = {}
const waitingLine = {}
const registerWait = url =>
    new Promise(resolve => {
        if (!waitingLine[url]) waitingLine[url] = []
        waitingLine[url].push(() => {
            //console.log("SVG LOAD - called", url)
            resolve(cache[url])
        })
    })
const svgLoad = async url => {
    if (!url) return null
    //console.log("SVG LOAD", url)
    if (cache[url]) {
        if (cache[url] === "loading") {
            //console.log("SVG LOAD - will wait", url)
            return registerWait(url)
        }
        //console.log("SVG LOAD - cached", url)
        return cache[url]
    }
    //console.log("SVG LOAD - do load", url)
    cache[url] = "loading"
    const svg = await axios.get(url)
    //console.log("SVG LOAD - loaded", url)
    cache[url] = svg.data
    if (waitingLine[url]) {
        const wl = waitingLine[url]
        delete waitingLine[url]
        //console.log("SVG LOAD - call others", url)
        wl.forEach(w => {
            requestAnimationFrame(() => {
                w()
            })
        })
    }
    return cache[url]
}

const dict = {
    "xmlns:xlink": "xmlnsXlink",
    "xml:space": "xmlSpace",
    class: "className",
    "enable-background": "enableBackground",
}

const parseStyle = s =>
    s
        .split(";")
        .map(s => s.trim())
        .filter(s => s !== "")
        .map(s => s.split(":"))
        .reduce((acc, item) => ({ ...acc, [dict[item[0]] ?? item[0]]: item[1] }), {})

const SVGNode = ({ node }) => {
    //console.log(node.nodeName, node.attributes, Array.from(node.attributes ?? []))
    const attrs = React.useMemo(() => {
        if (!node.attributes) return {}
        let i
        let attrs = {}
        for (i = 0; i < node.attributes.length; i++) {
            const a = node.attributes.item(i)
            //console.log("A", a.name, a.value)
            const name = dict[a.name] ?? a.name
            //if (name === "id") continue
            if (name === "style") attrs[name] = parseStyle(a.value)
            else attrs[name] = a.value
        }
        return attrs
    }, [node])
    if (node.nodeName === "#text") return node.textContent
    //console.log(attrs)
    if (node.nodeName === "style") return null
    const Tag = node.nodeName
    if (node.childNodes)
        return (
            <Tag {...attrs}>
                {Array.from(node.childNodes)?.map((node, i) => (
                    <SVGNode key={i} node={node} />
                ))}
            </Tag>
        )
    return <Tag {...attrs} />
}

const SVG = ({ domRef, context, url, ...props }) => {
    //console.log(url, props)
    const unmounted = useUnmounted()
    const [svg, setSVG] = React.useState()
    React.useEffect(() => {
        const load = async () => {
            //console.log("loading", url)
            const svg = await svgLoad(url)
            if (unmounted.current) return
            if (!svg) return
            const parser = new DOMParser()
            const data = parser.parseFromString(svg, "image/svg+xml")
            if (unmounted.current) return
            setSVG(data.documentElement)
            //console.log(data, data.documentElement, data.firstChild)
        }
        if (svg) return
        load()
    }, [unmounted, svg, url])
    const attrs = React.useMemo(() => {
        if (!svg)
            return {
                xmlns: "http://www.w3.org/2000/svg",
                xmlnsXlink: "http://www.w3.org/1999/xlink",
                version: "1.1",
                x: "0px",
                y: "0px",
                viewBox: "0 0 200 80",
                xmlSpace: "preserve",
            }
        let i
        let attrs = {}
        for (i = 0; i < svg.attributes.length; i++) {
            const a = svg.attributes.item(i)
            //console.log("A", a.name, a.value)
            const name = dict[a.name] ?? a.name
            if (name === "id") continue
            if (name === "style") {
                attrs[name] = parseStyle(a.value)
                if (props.style) attrs[name] = { ...attrs[name], ...props.style }
            } else attrs[name] = a.value
        }

        return attrs
    }, [svg, props])
    //console.log(svg?.children)

    return (
        <svg {...props} {...attrs}>
            {Array.from(svg?.childNodes ?? [])?.map((node, i) => (
                <SVGNode key={i} node={node} />
            ))}
        </svg>
    )
}
export default SVG
/*
  
*/
/*
  
  
  style={"enable-background:new 0 0 200 80;"
*/
