import { fieldsAll } from "ui/util"
import { useMemo } from "react"
import Field from "ui/Field"
import NodeWrap from "./NodeWrap"

const Homepage = ({context, _path, fieldPath, fieldEntity, homepage, config, node, ...props}) => {


    const newContext = useMemo(() => ({
        ...context,
        homepage,
    }), [context, homepage])
    const newPath = useMemo(() => [..._path, {type:"entity", entity: homepage}], [_path, homepage])
    const sf0 = {}

    return (
        <NodeWrap context={newContext} _path={newPath} elem-homepage="" type-homepage="">
            {fieldsAll(homepage, sf0, context.language)?.map((item, i) => (<Field context={newContext} _path={newPath} fieldPath={item.key} fieldEntity={homepage} value={item.value} type={item.type} config={item._e} key={i} _index={i}/>))}
        </NodeWrap>
    )
}
export default Homepage
/*
{
  "layout": {
    "data": {
      "name": "NodeWrap"
    },
    "children": [
      {
        "data": {
          "_e": {
            "name": {
              "_type": "op"
            }
          },
          "name": [
            "f",
            "e._all_"
          ]
        }
      }
    ]
  }
}
*/
