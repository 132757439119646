import { useMemo } from "react"
import { useSelection } from "./hooks/useSelection"

const Select = ({ config, context, _path, fieldPath, fieldEntity, collection, type, cond, limit, children }) => {
    const [selectResults, selectTotal] = useSelection(collection, type, cond, limit)
    //console.log("SELECT", config, collection, type, cond, selectResults)
    const newContext = useMemo(
        () => ({
            ...context,
            selectResults,
            selectTotal,
        }),
        [context, selectResults, selectTotal]
    )
    return children(newContext, selectResults, selectTotal)
}

/*const Select = ({ context, state, collection, filter, limit, children }) => {
    const newContext = useSelection(context, state, collection, filter, limit)
    //console.log(context, collection, limit, newContext)

    return children(newContext)
}*/

export default Select
