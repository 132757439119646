import { useAdmin } from "ui/hooks/useAdmin"
import { useMemo } from "react"

const NodeWrap = ({context, _path, fieldPath, fieldEntity, children, ...props}) => {


    const [ref, newPath] = useAdmin(context, _path, fieldPath, fieldEntity, null, "NodeWrap")

    return (
        <div {...props} ref={ref}  elem-node-wrap="" region-content="">
            <div region-inner="">
{children}
            </div>
        </div>
    )
}
export default NodeWrap
/*
{
  "layout": {
    "data": {
      "name": "div",
      "attr": {
        "region-content": ""
      }
    },
    "children": [
      {
        "data": {
          "name": "div",
          "attr": {
            "renderChildren": true,
            "_e": {
              "renderChildren": {
                "_type": "bool"
              }
            },
            "region-inner": ""
          }
        }
      }
    ]
  }
}
*/
