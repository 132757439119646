import customValidators from "../conf/validators"
//import Data from "./data";
//import Type from "./type"
import { getKeyType } from "./type"
import { $lineage } from "./symbols"

const validators = {
    t: (value, type) => {
        //console.log("validate t", value, type)
        if (typeof value === "undefined" || value === null) {
            if (type.optional || !type.default) return true
            return false
        }
        return true
    },
    bool: value => {
        //console.log("validate string", value, type)
        if (typeof value === "undefined") return true
        return typeof value === "boolean"
    },
    string: value => {
        //console.log("validate string", value, type)
        if (typeof value === "undefined" || value === null) return true
        if (typeof value !== "string") return false
        return true
    },
    number: value => {
        if (typeof value === "undefined" || value === null) return true
        if (typeof value !== "number") return false
        return true
    },
    list: (value, type) => {
        //console.log("validate list", value, type)
        if (typeof value === "undefined" || value === null) {
            if (type.optional || !type.min) return true
            return false
        }
        if (!Array.isArray(value)) return false
        if (type.min && value.length < type.min) return false
        if (type.max && value.length > type.max) return false
        return true
    },
    map: value => {
        if (typeof value === "undefined" || value === null) return true
        if (Array.isArray(value) || typeof value !== "object") return false
        return true
    },
    value: v => validate(v.value, getKeyType("value", v)),
    ...customValidators,
}
//export default validators
export const validate = (value, type) =>
    type?.[$lineage]?.reduceRight((acc, t) => {
        if (!acc) return acc
        if (validators[t]) return validators[t](value, type)
        return true
    }, true) ?? false
